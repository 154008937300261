import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Pagination from "./components/Pagination";
import { getArticles } from "./services/articleService";
import "./homepage.css";

const Homepage = () => {
  const [articles, setArticles] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 10;

  const formatArticleDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "";
    }
    return new Intl.DateTimeFormat("fr-FR").format(date);
  };

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const data = await getArticles(page, limit);
        setArticles(data.articles);
        setTotalPages(data.pages);
      } catch (error) {
        console.error("Erreur lors de la récupération des articles:", error);
      }
    };

    fetchArticles();
  }, [page]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const recentArticles = articles
    .slice()
    .sort((a, b) => new Date(b.date) - new Date(a.date));

  const oldestArticles = articles
    .slice()
    .sort((a, b) => new Date(a.date) - new Date(b.date));

  return (
    <div className="homepage-container">
      <div className="jumbotron">
        <div>
          <div>
            <h1>
              {" "}
              <p>Bienvenue</p> sur Globoal.fr
            </h1>
            <p>
              "The Place To Be" pour une approche globale du bien-être,
              atteindre vos objectifs, vivre une vie plus saine et plus
              heureuse. Découvrez les derniers articles, outils, et ressources
              sur la santé, la nutrition, le sport, la psychologie, la
              spiritualité et bien plus encore. Rejoignez notre communauté et
              commencez votre voyage vers une vie plus saine et plus heureuse
              dès aujourd'hui !
            </p>
            Recevez en avant-première des mises à
            jour par e-mail en vous <Link to="/a-propos" className="banner-link">inscrivant à notre newsletter.</Link>
          </div>
          <div></div>
        </div>
      </div>
      <div className="recent-section">
        <h2>Articles récents</h2>
        {recentArticles.map((article) => (
          <div key={article._id}>
            <Link to={`/article/details/${article.slug}`}>
              {article.imageUrl && (
                <img
                  src={article.imageUrl}
                  alt={article.title}
                  className="article-image"
                  loading="lazy"
                />
              )}
              <h1>{article.title}</h1>
            </Link>
            <div className="dessuspublié">
              <small>Publié le: {formatArticleDate(article.date)}</small> |{" "}
              <strong>👍</strong> <strong>{article.likes}  </strong>|{" "}
              {/* <strong>♡</strong> <small>0</small> */}
            </div>
          </div>
        ))}
      </div>
      <div className="featured-section">
        <h2>Articles les plus anciens</h2>
        {oldestArticles.map((article) => (
          <div key={article._id}>
            <Link to={`/article/details/${article.slug}`}>
              {article.imageUrl && (
                <img
                  src={article.imageUrl}
                  alt={article.title}
                  className="article-image"
                  loading="lazy"
                />
              )}
              <h1>{article.title}</h1>
            </Link>
            <div className="dessuspublié">
              <small>Publié le: {formatArticleDate(article.date)}</small> |{" "}
              <strong>👍</strong> <strong>{article.likes}  </strong>|{" "}
{/*               <strong>♡</strong> <small>0</small>
 */}            </div>
          </div>
        ))}
      </div>
      <div className="pagination">
        <Pagination
          page={page}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default Homepage;
