import axios from './axiosConfig';

export const register = async (userData) => {
  const response = await axios.post('/auth/register', userData);
  return response.data;
};

export const login = async (email, password) => {
  try {
    const response = await axios.post('/auth/login', { email, password });

    const {authenticated, role } = response.data;

    if (authenticated) {
      console.log('Utilisateur authentifié avec succès', response.data);
      return { authenticated: true, role };
    } else {
      console.error('Authentification échouée');
      return { authenticated: false };
    }

  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    }
    throw new Error('Une erreur est survenue lors de la connexion');
  }
};


export const getUserProfile = async () => {
  try {
    console.log('log avant axios')
    const response = await axios.get('/auth/profile');  
    console.log("User Profile Data:", response.data.user);  

    return response.data.user; 
  } catch (error) {
    console.error("Error fetching user profile:", error);
    throw error;
  }
};



export const logout = async () => {
  await axios.post('/auth/logout');
  window.location.href = '/';
};

export const isAuthenticated = async () => {
  try {
    const response = await axios.get('/auth/status');  
    return response.data.authenticated;
  } catch (error) {
    console.error('Erreur lors de la vérification de l\'authentification:', error);
    return false;
  }
};
