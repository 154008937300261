import React from 'react';
import Navbar from './Navbar';
import NavbarAdmin from './AdminNavbar';
import BientotBanner from './BientotBanner';
import Breadcrumb from './Breadcrumb';

const Layout = ({ children, isLoggedIn, handleLogout, userRole }) => {
  return (
    <div>
      <BientotBanner />
      {userRole === 'admin' ? (
        <NavbarAdmin isLoggedIn={isLoggedIn} handleLogout={handleLogout} />
      ) : (
        <Navbar isLoggedIn={isLoggedIn} handleLogout={handleLogout} />
      )}
      <Breadcrumb />
      <main>{children}</main>
    </div>
  );
};

export default Layout;
