/* import axiosInstance from './axiosConfig';
import { getUserProfile } from './authService';
import { toast } from 'react-toastify';

const userTrackerEntriesService = {
  //Mood

  getUserEntries: async (userId) => {
    try {
      const response = await axiosInstance.get(`/moods/user/${userId}`);
      return response.data.userMoods;
    } catch (error) {
      console.error("Error fetching user entries:", error);
      toast.error("Error fetching user entries");
      throw error;
    }
  },

  //Sleep Quality

  getUserQualitySleepEntries: async (userId) => {
    try {
      const response = await axiosInstance.get(`/sleep-quality/${userId}`);
      return response.data.entries;
    } catch (error) {
      console.error("Error fetching user entries:", error);
      toast.error("Error fetching user entries");
      throw error;
    }
  },

  //Sleep Quantity
  getUserQuantitySleepEntries: async (userId) => {
    try {
      const response = await axiosInstance.get(`/sleep-entries/${userId}`);
      return response.data.entries;
    } catch (error) {
      console.error("Error fetching user entries:", error);
      toast.error("Error fetching user entries");
      throw error;
    }
  },

    //Wake Sleep Record
    getSleepDuration: async (userId) => {
      try {
      const response = await axiosInstance.get(`/duration/${userId}`);
      return response.data.duration;
    } catch (error) {
      console.error("error fetching sleep durations", error);
      toast.error("Error fetching user sleep durations")
    }
  },


  //Water
  getWaterEntries: async (userId) => {
    try {
      const response = await axiosInstance.get(`/water-consumptions/${userId}`);
      return { success: true, data: response.data.consumptions };
    } catch (error) {
      return { success: false, message: error.message };
    }
  },

  
  //Weight Entries
 getWeightEntries: async (userId) => {
    try {
      const response = await axiosInstance.get(`/weight/${userId}`);
      return { success: true, data: response.data };
    } catch (error) {
      return { data: [],success: false, message: error.message };
    }
  },
}

export default userTrackerEntriesService;
 */

import axiosInstance from './axiosConfig';
import { toast } from 'react-toastify';
import { getUserProfile } from './authService';

const userTrackerEntriesService = {
  //Mood
  getUserEntries: async () => {
    try {
      const user = await getUserProfile();  // Récupère l'utilisateur connecté
      const userId = user.userId;  // Récupère l'ID de l'utilisateur
      const response = await axiosInstance.get(`/moods/user/${userId}`);
      return response.data.userMoods;
    } catch (error) {
      console.error("Error fetching user entries:", error);
      toast.error("Error fetching user entries");
      throw error;
    }
  },

  //Sleep Quality
  getUserQualitySleepEntries: async () => {
    try {
      const user = await getUserProfile();  
      const userId = user.userId;  
      const response = await axiosInstance.get(`/sleep-quality/${userId}`);
      return response.data.entries;
    } catch (error) {
      console.error("Error fetching user entries:", error);
      toast.error("Error fetching user entries");
      throw error;
    }
  },

  //Sleep Quantity
  getUserQuantitySleepEntries: async () => {
    try {
      const user = await getUserProfile();  
      const userId = user.userId;  
      const response = await axiosInstance.get(`/sleep-entries/${userId}`);
      return response.data.entries;
    } catch (error) {
      console.error("Error fetching user entries:", error);
      toast.error("Error fetching user entries");
      throw error;
    }
  },

  //Wake Sleep Record
  getSleepDuration: async () => {
    try {
      const user = await getUserProfile();  
      const userId = user.userId;  
      const response = await axiosInstance.get(`/duration/${userId}`);
      return response.data.duration;
    } catch (error) {
      console.error("error fetching sleep durations", error);
      toast.error("Error fetching user sleep durations");
      throw error;
    }
  },

  //Water
  getWaterEntries: async () => {
    try {
      const user = await getUserProfile();  
      const userId = user.userId;  
      const response = await axiosInstance.get(`/water-consumptions/${userId}`);
      return { success: true, data: response.data.consumptions };
    } catch (error) {
      return { success: false, message: error.message };
    }
  },

  //Weight Entries
  getWeightEntries: async () => {
    try {
      const user = await getUserProfile();  
      const userId = user.userId;  
      const response = await axiosInstance.get(`/weight/${userId}`);
      return { success: true, data: response.data };
    } catch (error) {
      return { data: [], success: false, message: error.message };
    }
  },
};

export default userTrackerEntriesService;
