import React from 'react';
import { useNavigate } from 'react-router-dom';
import './backButton.css';

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <button className="back-button" onClick={() => navigate(-1)}>
     Retour ←
    </button>
  );
};

export default BackButton;
