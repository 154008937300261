import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './articleDetails.css';
import ReactMarkdown from 'react-markdown';
import PinterestShareButton from './buttons/PinterestShareButton';
import BackButton from './BackButton';
import LikeButton from './LikeButton';
import { getArticleBySlug } from '../services/articleService';
import { handleAxiosError } from '../services/errorService';

const ArticleDetails = () => {
  const { slug } = useParams();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArticleDetails = async () => {
      try {
        const data = await getArticleBySlug(slug);
        setArticle(data.article);
      } catch (error) {
        handleAxiosError(error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchArticleDetails();
  }, [slug]);

  if (loading) {
    return <p>Chargement de l'article...</p>;
  }

  if (error) {
    return <p>Erreur: {error}</p>;
  }

  if (!article) {
    return <p>Article non trouvé</p>;
  }

  return (
    <div className="article-container">
      <div className="article">
        <h1>{article.title}</h1>
        {article.imageUrl && <img src={article.imageUrl} alt="Article" loading="lazy" />}
        <div>
          <p>
            <strong>👍</strong>
            <strong> {article.likes}</strong>
          </p>
        </div>
        <PinterestShareButton url={window.location.href} media={article.imageUrl} description={article.title} />
        {article.sections && article.sections.length > 0 ? (
          article.sections.map((section, index) => (
            <div key={index}>
              <h2>{section.heading}</h2>
              {section.content && section.content.length > 0 ? (
                section.content.map((contentItem, contentIndex) => (
                  <div key={`${index}-${contentIndex}`}>
                    <ReactMarkdown>{contentItem.text}</ReactMarkdown>
                  </div>
                ))
              ) : (
                <p>No content found for this section.</p>
              )}
            </div>
          ))
        ) : (
          <p>No sections found for this article.</p>
        )}
      </div>
      <LikeButton articleId={article._id} />
      <BackButton />
    </div>
  );
};

export default ArticleDetails;

