/* import React, { useState, useEffect } from 'react';
import trackerService from '../../services/trackerService';
import userTrackerEntriesService from '../../services/userTrackerEntriesService';

const WeightTracker = () => {
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const [weightEntries, setWeightEntries] = useState([]);
    const userId = sessionStorage.getItem('userId')?.replace(/"/g, '');
    
    const handleAddWeight = async (weight) => {
        try {
          const date = new Date().toISOString();
          console.log('Adding weight entry:', { userId, weight, date });
          const response = await trackerService.addWeightEntry(userId, weight, date);
          if (response.success) {
            setMessage('Weight entry added successfully');
            setError(null);
            fetchWeightEntries();
          } else {
            setError('Error adding weight entry: ' + response.message);
            setMessage(null);
          }
        } catch (error) {
          console.error('Error adding weight entry:', error);
          setError('Error adding weight entry');
          setMessage(null);
        }
      };
    
      const fetchWeightEntries = async () => {
        try {
          console.log('Fetching weight entries for user:', userId);
          const response = await userTrackerEntriesService.getWeightEntries(userId);
          setWeightEntries(response.data || []);
          setError(null);
        } catch (error) {
          console.error('Error fetching weight entries:', error);
          setError('Error fetching weight entries');
          setWeightEntries([]);
        }
      };
    
      useEffect(() => {
        fetchWeightEntries();
      }, []);

      return (
        <div className="weight-tracker-container">
          <h1>Mon Poids</h1>
          {error && <div className="error">{error}</div>}
          {message && <div className="message">{message}</div>}
         
          <div className="weight-entry-container">
            <h2>Ajouter Une Valeur</h2>
            <input
              type="number"
              placeholder="Entrer le poids en kg"
              onKeyDown={(e) => {
                if (e.key === 'Enter') handleAddWeight(parseFloat(e.target.value));
              }}
            />
          </div>
          <div className="weight-entries-container">
            <h2>Mes Pesées</h2>
            {weightEntries.length === 0 ? (
              <p>Pas d'entrée trouvée.</p>
            ) : (
              <ul>
                {weightEntries.map((entry) => (
                  <li key={entry._id}>
                  {entry.weight} kg, Date: {new Date(entry.date).toLocaleString()}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      );
    };
    
    export default WeightTracker; */
    import React, { useState, useEffect } from 'react';
import trackerService from '../../services/trackerService';
import userTrackerEntriesService from '../../services/userTrackerEntriesService';
import { getUserProfile } from '../../services/authService';

const WeightTracker = () => {
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const [weightEntries, setWeightEntries] = useState([]);

    const fetchWeightEntries = async () => {
        try {
            const user = await getUserProfile(); 
            const userId = user.userId;
            console.log('Fetching weight entries for user:', userId);
            const response = await userTrackerEntriesService.getWeightEntries(userId);
            setWeightEntries(response.data || []);
            setError(null);
        } catch (error) {
            console.error('Error fetching weight entries:', error);
            setError('Error fetching weight entries');
            setWeightEntries([]);
        }
    };

    const handleAddWeight = async (weight) => {
        try {
            const user = await getUserProfile();
            const userId = user.userId;
            const date = new Date().toISOString();
            console.log('Adding weight entry:', { userId, weight, date });
            const response = await trackerService.addWeightEntry(weight, date);
            if (response.success) {
                setMessage('Weight entry added successfully');
                setError(null);
                fetchWeightEntries();
            } else {
                setError('Error adding weight entry: ' + response.message);
                setMessage(null);
            }
        } catch (error) {
            console.error('Error adding weight entry:', error);
            setError('Error adding weight entry');
            setMessage(null);
        }
    };

    useEffect(() => {
        fetchWeightEntries();
    }, []);

    return (
        <div className="weight-tracker-container">
            <h1>Mon Poids</h1>
            {error && <div className="error">{error}</div>}
            {message && <div className="message">{message}</div>}
         
            <div className="weight-entry-container">
                <h2>Ajouter Une Valeur</h2>
                <input
                    type="number"
                    placeholder="Entrer le poids en kg"
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') handleAddWeight(parseFloat(e.target.value));
                    }}
                />
            </div>
            <div className="weight-entries-container">
                <h2>Mes Pesées</h2>
                {weightEntries.length === 0 ? (
                    <p>Pas d'entrée trouvée.</p>
                ) : (
                    <ul>
                        {weightEntries.map((entry) => (
                            <li key={entry._id}>
                                {entry.weight} kg, Date: {new Date(entry.date).toLocaleString()}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default WeightTracker;
