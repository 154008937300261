/* import React, { useState } from "react";
import { Link } from "react-router-dom";
import './register.css'

const Register = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("Les mots de passe ne correspondent pas");
      return;
    }

    const userData = {
      username,
      email,
      password,
    };

    try {
      const response = await fetch("http://localhost:5000/api/auth/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });

      if (!response.ok) {
        throw new Error("Erreur lors de l'inscription");
      }

      const result = await response.json();
      alert("Inscription réussie");
      console.log(result);
    } catch (error) {
      alert("Une erreur est survenue: " + error.message);
    }
  };

  return (
    <div className="register-container">
      <div className="form-container">
        <div className="register-title">
          <h2>Inscription</h2>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label className="register-label">Nom d'utilisateur:</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="register-input"
              required
            />
          </div>
          <div className="form-group">
            <label className="register-label">Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="register-input"
              required
            />
          </div>
          <div className="form-group">
            <label className="register-label">Mot de passe:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="register-input"
              required
            />
          </div>
          <div className="form-group">
            <label className="register-label">Confirmer le mot de passe:</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="register-input"
              required
            />
          </div>
          <button type="submit">S'inscrire</button>
        </form>
      </div>
    </div>
  );
};

export default Register;
 */

// src/components/Register.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { register } from "../services/authService";
import './register.css';

const Register = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("Les mots de passe ne correspondent pas");
      return;
    }

    const userData = {
      username,
      email,
      password,
    };

    try {
      const result = await register(userData);
      alert("Inscription réussie");
      console.log(result);
      window.location.href = '/login';
    } catch (error) {
      console.error("Erreur lors de l'inscription:", error);
      setErrorMessage(error.message);
    }
  };

  return (
    <div className="register-container">
      <div className="form-container">
        <div className="register-title">
          <h2>Inscription</h2>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label className="register-label">Nom d'utilisateur:</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="register-input"
              required
            />
          </div>
          <div className="form-group">
            <label className="register-label">Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="register-input"
              required
            />
          </div>
          <div className="form-group">
            <label className="register-label">Mot de passe:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="register-input"
              required
            />
          </div>
          <div className="form-group">
            <label className="register-label">Confirmer le mot de passe:</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="register-input"
              required
            />
          </div>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <button type="submit">S'inscrire</button>
        </form>
        <div className='login-link'>
          <small><Link to='/login'><div><p>Déjà membre? Connectez-vous ici</p></div></Link></small>
        </div>
      </div>
    </div>
  );
};

export default Register;
