/* import { NavLink } from 'react-router-dom';
import { ReactComponent as Brand } from '../assets/icons/globoal.svg';
import './navbar.css';

const NavbarAdmin = ({isLoggedIn, handleLogout}) => {
  return (
    <nav className="navbar">
      <div className="container">
        <div className="logo">
          <Brand />
        </div>
        <div className="nav-elements">
          <ul>
            <li>
              <NavLink to="/admin">Admin Dashboard</NavLink>
            </li>
            <li>
              <NavLink to="/articlelist">Articles</NavLink>
            </li>
            <li>
              <NavLink to="/userslist">Liste des utilisateurs</NavLink>
            </li>
            {isLoggedIn ? (
              <li>
                <button onClick={handleLogout}>Se déconnecter</button>
              </li>
            ) : (
              <li>
                <NavLink to="/login">Se connecter</NavLink>
              </li>
            )}{" "} 
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavbarAdmin;
 */

import { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { ReactComponent as Hamburger } from "../assets/icons/hamburger.svg";
import { ReactComponent as Brand } from "../assets/icons/globoal.svg";
import "./navbar.css";

const NavbarAdmin = ({ handleLogout, isLoggedIn }) => {
  const [showNavbar, setShowNavbar] = useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  return (
    <nav className="navbar">
      <div className="container">
        <div className="logo">
          <Link to="/admin"><Brand /></Link>
        </div>
        <div className="menu-icon" onClick={handleShowNavbar}>
          <Hamburger />
        </div>
        <div className={`nav-elements ${showNavbar && "active"}`}>
          <ul>
            <li>
              <NavLink to="/admin">Admin Dashboard</NavLink>
            </li>
            <li>
              <NavLink to="/admin/users">User Management</NavLink>
            </li>
            <li>
              <NavLink to="/admin/articles">Article Management</NavLink>
            </li>
            {isLoggedIn ? (
              <li>
                <button onClick={handleLogout}>Se déconnecter</button>
              </li>
            ) : (
              <li>
                <NavLink to="/login">Connexion</NavLink>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavbarAdmin;
