/* import { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { ReactComponent as Hamburger } from "../assets/icons/hamburger.svg";
import { ReactComponent as Brand } from "../assets/icons/globoal.svg";
import "./navbar.css";

const Navbar = ({ handleLogout, isLoggedIn }) => {
  const [showNavbar, setShowNavbar] = useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  return (
    <nav className="navbar">
      <div className="container">
        <div className="logo">
          <Link to="/home"><Brand /></Link>
        </div>
        <div className="menu-icon" onClick={handleShowNavbar}>
          <Hamburger />
        </div>
        <div className={`nav-elements ${showNavbar && "active"}`}>
          <ul>
            <li>
              <NavLink to="/home">Accueil</NavLink>
            </li>
            <li>
              <NavLink to="/dashboard">Dashboard</NavLink>
            </li>
            <li>
              <NavLink to="/a-propos">Qu'est-Ce Que C'est ?</NavLink>
            </li>
            <li>
              <NavLink to="/blog">Blog</NavLink>
            </li>
            {isLoggedIn ? (
              <li>
                <button onClick={handleLogout}>Se déconnecter</button>
              </li>
            ) : (
              <li>
                <NavLink to="/login">Connexion</NavLink>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
 */

import { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { ReactComponent as Hamburger } from "../assets/icons/hamburger.svg";
import { ReactComponent as Brand } from "../assets/icons/globoal.svg";
import "./navbar.css";

const Navbar = ({ handleLogout, isLoggedIn }) => {
  const [showNavbar, setShowNavbar] = useState(false);

  // Variables de contrôle pour la publication
  const isDashboardPublished = false; // Changez cette valeur pour tester l'affichage
  const isLoginPublished = false; // Changez cette valeur pour tester l'affichage

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  return (
    <nav className="navbar">
      <div className="container">
        <div className="logo">
          <Link to="/home"><Brand /></Link>
        </div>
        <div className="menu-icon" onClick={handleShowNavbar}>
          <Hamburger />
        </div>
        <div className={`nav-elements ${showNavbar && "active"}`}>
          <ul>
            <li>
              <NavLink to="/home">Accueil</NavLink>
            </li>
            {isDashboardPublished && (
              <li>
                <NavLink to="/dashboard">Dashboard</NavLink>
              </li>
            )}
            <li>
              <NavLink to="/a-propos">Qu'est-Ce Que C'est ?</NavLink>
            </li>
            <li>
              <NavLink to="/blog">Blog</NavLink>
            </li>
            <li>
              <NavLink to="/contact">Contact</NavLink>
            </li>
            {!isLoggedIn && isLoginPublished && (
              <li>
                <NavLink to="/login">Connexion</NavLink>
              </li>
            )}
            {isLoggedIn && (
              <li>
                <button onClick={handleLogout}>Se déconnecter</button>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
