import React from 'react';
import'./pinterestShareButton.css';

const PinterestShareButton = ({ url, media, description }) => {
  return (
    <a
      href={`https://www.pinterest.com/pin/create/button/?url=${encodeURIComponent(url)}&media=${encodeURIComponent(media)}&description=${encodeURIComponent(description)}`}
      data-pin-do="buttonPin"
      data-pin-config="above"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src="//assets.pinterest.com/images/pidgets/pinit_fg_en_rect_gray_20.png" alt="Pin it" />
    </a>
  );
};

export default PinterestShareButton;
