import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const defaultOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const notifySuccess = (message, options = {}) => {
  toast.success(message, { ...defaultOptions, ...options });
};

export const notifyError = (message, options = {}) => {
  toast.error(message, { ...defaultOptions, ...options });
};

export const notifyInfo = (message, options = {}) => {
  toast.info(message, { ...defaultOptions, ...options });
};

export const notifyWarning = (message, options = {}) => {
  toast.warning(message, { ...defaultOptions, ...options });
};

const toastService = {
  notifySuccess,
  notifyError,
  notifyInfo,
  notifyWarning,
};

export default toastService;
