/* import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from '../../services/axiosConfig'; 
import { notifySuccess, notifyError } from '../../services/toastService';

const VerifyEmail = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const token = searchParams.get('token');
    console.log("Token: ", token); 

    if (token) {
      verifyEmail(token);
    }
  }, [searchParams]);

  const verifyEmail = async (token) => {
    try {
      const response = await axios.get(`/api/auth/verify-email?token=${token}`, {
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0'
        }
      });
      notifySuccess(response.data.message);
      
      setTimeout(() => {
        window.location.href = '/login';
      }, 2000);
    } catch (error) {
      notifyError(error.response?.data?.message || 'Erreur de vérification');
    }
  };

  return (
    <div>
      <h2>Vérification de l'email en cours...</h2>
    </div>
  );
};

export default VerifyEmail;
 */

import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { notifySuccess, notifyError } from '../../services/toastService';

const VerifyEmail = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const token = searchParams.get('token');
    console.log("Token: ", token);

    if (token) {
      verifyEmail(token);
    }
  }, [searchParams]);

  const verifyEmail = async (token) => {
    try {
      const response = await axios.get(`http://localhost:5000/api/auth/verify-email?token=${token}`, {
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0'
        }
      });
      notifySuccess(response.data.message);
      
      setTimeout(() => {
        window.location.href = '/login';
      }, 2000);
    } catch (error) {
      notifyError(error.response?.data?.message || 'Erreur de vérification');
    }
  };

  return (
    <div>
      <h2>Vérification de l'email en cours...</h2>
    </div>
  );
};

export default VerifyEmail;
