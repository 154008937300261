/* import axios from './axiosConfig';
import storageService from './storageService';

export const createObjectif = async (objectif) => {
  const userId = storageService.getSessionStorage('userId');
  const response = await axios.post('/objectif/create', { objectif, userId });
  return response.data;
};
 */
import axios from './axiosConfig';

export const createObjectif = async (objectif) => {
  const response = await axios.post('/objectif/create', { objectif });
  return response.data;
};
