import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getArticles } from '../services/articleService';

const ArticleList = () => {
  const [articles, setArticles] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 10;

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const data = await getArticles(page, limit);
        setArticles(data.articles);
        setTotalPages(data.pages);
      } catch (error) {
        console.error('Erreur lors de la récupération des articles:', error);
      }
    };

    fetchArticles();
  }, [page]);

  const handleNext = () => {
    if (page < totalPages) setPage(page + 1);
  };

  const handlePrevious = () => {
    if (page > 1) setPage(page - 1);
  };

  return (
    <div className='container'>
      <h1>Blog</h1>
      <div>
        {articles.map(article => (
          <div key={article._id}>
            <h2>{article.title}</h2>
            <Link to={`/article/edit/${article._id}`}>Voir et Éditer</Link>
          </div>
        ))}
      </div>
      <div className="pagination">
        <button className="arrow" onClick={handlePrevious} disabled={page === 1}>&laquo;</button>
        {page > 2 && <span className="page-number" onClick={() => setPage(1)}>1</span>}
        {page > 3 && <span className="dots">...</span>}
        {page > 1 && <span className="page-number" onClick={() => setPage(page - 1)}>{page - 1}</span>}
        <span className="page-number current">{page}</span>
        {page < totalPages && <span className="page-number" onClick={() => setPage(page + 1)}>{page + 1}</span>}
        {page < totalPages - 2 && <span className="dots">...</span>}
        {page < totalPages - 1 && <span className="page-number" onClick={() => setPage(totalPages)}>{totalPages}</span>}
        <button className="arrow" onClick={handleNext} >&raquo;</button>
      </div>
    </div>
  );
};

export default ArticleList;
