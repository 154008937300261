/* import React, { useState, useEffect } from 'react';
import trackerService from '../services/trackerService';
import userTrackerEntriesService from '../services/userTrackerEntriesService';
import './sleepTracker.css';

const SleepTracker = () => {
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [sleepEntries, setSleepEntries] = useState([]);
  const userId = sessionStorage.getItem('userId')?.replace(/"/g, '');

  const handleAddSleep = async (minutes) => {
    try {
      const date = new Date().toISOString();
      console.log('Adding sleep entry:', { userId, minutes, date });
      const response = await trackerService.addSleepQuantity(userId, minutes, date);
      if (response.success) {
        setMessage('Entry added successfully');
        setError(null);
        fetchSleepEntries();
      } else {
        setError('Error adding sleep entry');
        setMessage(null);
      }
    } catch (error) {
      console.error('Error adding sleep entry:', error);
      setError('Error adding sleep entry');
      setMessage(null);
    }
  };

  const fetchSleepEntries = async () => {
    try {
      console.log('Fetching sleep entries for user:', userId);
      const entries = await userTrackerEntriesService.getUserQuantitySleepEntries(userId);
      setSleepEntries(entries || []);
      setError(null);
    } catch (error) {
      console.error('Error fetching sleep entries:', error);
      setError('Error fetching sleep entries');
      setSleepEntries([]);
    }
  };

  const handleWakeRecord = async () => {
    try {
      const date = new Date().toISOString();
      console.log('Recording wake time:', { userId, date });
      const response = await trackerService.wakeRecord(userId, date);
      if (response.success) {
        setMessage('Wake time recorded successfully');
        setError(null);
        fetchSleepEntries();
      } else {
        setError(`Error recording wake time: ${response.message}`);
        setMessage(null);
      }
    } catch (error) {
      console.error(`Error recording wake time: ${error.message}`);
      setError(`Error recording wake time: ${error.message}`);
      setMessage(null);
    }
  };

  const handleSleepRecord = async () => {
    try {
      const date = new Date().toISOString();
      console.log('Recording sleep time:', { userId, date });
      const response = await trackerService.sleepRecord(userId, date);
      if (response.success) {
        setMessage('Sleep time recorded successfully');
        setError(null);
        fetchSleepEntries();
      } else {
        setError(`Error recording sleep time: ${response.message}`);
        setMessage(null);
      }
    } catch (error) {
      console.error(`Error recording sleep time: ${error.message}`);
      setError(`Error recording sleep time: ${error.message}`);
      setMessage(null);
    }
  };

  useEffect(() => {
    fetchSleepEntries();
  }, []);

  return (
    <div className="sleep-tracker-container">
      <h1>Durée de Sommeil</h1>
      {error && <div className="error">{error}</div>}
      {message && <div className="message">{message}</div>}
      <div className="buttons-container">
        {[5, 10, 30, 60].map(minutes => (
          <button
            key={minutes}
            className="sleep-button"
            onClick={() => handleAddSleep(minutes)}
          >
            +{minutes} min
          </button>
        ))}
      </div>
      <div className='wake-sleep-record-buttons-container'>
        <button
          className="wake-record"
          onClick={handleWakeRecord}
        >
          Debout !
        </button>
        <button
          className="sleep-record"
          onClick={handleSleepRecord}
        >
          Au lit !
        </button>
      </div>
      <div className="sleep-entries-container">
        <h2>Mon Suivi de Sommeil en Quantité</h2>
        {sleepEntries.length === 0 ? (
          <p>No sleep entries found.</p>
        ) : (
          <ul>
            {sleepEntries.map((entry) => (
              <li key={entry._id}>
                Min: {entry.minutes}, Reposé(e): {entry.rested ? 'Oui' : 'Non'}, 
                Date: {new Date(entry.date).toLocaleString()}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default SleepTracker;
 */
import React, { useState, useEffect } from 'react';
import trackerService from '../services/trackerService';
import userTrackerEntriesService from '../services/userTrackerEntriesService';
import './sleepTracker.css';

const SleepTracker = () => {
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [sleepEntries, setSleepEntries] = useState([]);

  const handleAddSleep = async (minutes) => {
    try {
      const date = new Date().toISOString();
      const response = await trackerService.addSleepQuantity(minutes, date);
      if (response.success) {
        setMessage('Entry added successfully');
        setError(null);
        fetchSleepEntries();
      } else {
        setError('Error adding sleep entry');
        setMessage(null);
      }
    } catch (error) {
      console.error('Error adding sleep entry:', error);
      setError('Error adding sleep entry');
      setMessage(null);
    }
  };

  const fetchSleepEntries = async () => {
    try {
      const entries = await userTrackerEntriesService.getUserQuantitySleepEntries();
      setSleepEntries(entries || []);
      setError(null);
    } catch (error) {
      console.error('Error fetching sleep entries:', error);
      setError('Error fetching sleep entries');
      setSleepEntries([]);
    }
  };

  const handleWakeRecord = async () => {
    try {
      const date = new Date().toISOString();
      const response = await trackerService.wakeRecord(date);
      if (response.success) {
        setMessage('Wake time recorded successfully');
        setError(null);
        fetchSleepEntries();
      } else {
        setError(`Error recording wake time: ${response.message}`);
        setMessage(null);
      }
    } catch (error) {
      console.error(`Error recording wake time: ${error.message}`);
      setError(`Error recording wake time: ${error.message}`);
      setMessage(null);
    }
  };

  const handleSleepRecord = async () => {
    try {
      const date = new Date().toISOString();
      const response = await trackerService.sleepRecord(date);
      if (response.success) {
        setMessage('Sleep time recorded successfully');
        setError(null);
        fetchSleepEntries();
      } else {
        setError(`Error recording sleep time: ${response.message}`);
        setMessage(null);
      }
    } catch (error) {
      console.error(`Error recording sleep time: ${error.message}`);
      setError(`Error recording sleep time: ${error.message}`);
      setMessage(null);
    }
  };

  useEffect(() => {
    fetchSleepEntries();
  }, []);

  return (
    <div className="sleep-tracker-container">
      <h1>Durée de Sommeil</h1>
      {error && <div className="error">{error}</div>}
      {message && <div className="message">{message}</div>}
      <div className="buttons-container">
        {[5, 10, 30, 60].map(minutes => (
          <button
            key={minutes}
            className="sleep-button"
            onClick={() => handleAddSleep(minutes)}
          >
            +{minutes} min
          </button>
        ))}
      </div>
      <div className='wake-sleep-record-buttons-container'>
        <button
          className="wake-record"
          onClick={handleWakeRecord}
        >
          Debout !
        </button>
        <button
          className="sleep-record"
          onClick={handleSleepRecord}
        >
          Au lit !
        </button>
      </div>
      <div className="sleep-entries-container">
        <h2>Mon Suivi de Sommeil en Quantité</h2>
        {sleepEntries.length === 0 ? (
          <p>No sleep entries found.</p>
        ) : (
          <ul>
            {sleepEntries.map((entry) => (
              <li key={entry._id}>
                Min: {entry.minutes}, Reposé(e): {entry.rested ? 'Oui' : 'Non'}, 
                Date: {new Date(entry.date).toLocaleString()}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default SleepTracker;
