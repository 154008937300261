// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.article-management {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .article-management h2 {
    margin-bottom: 20px;
  }
  
  .article-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .article-table th, .article-table td {
    padding: 12px;
    border: 1px solid #ddd;
  }
  
  .article-table th {
    background-color: orange;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/admin/styles/articleManagement.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,kBAAkB;IAClB,wCAAwC;EAC1C;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,yBAAyB;EAC3B;;EAEA;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,wBAAwB;EAC1B","sourcesContent":[".article-management {\n    background: #fff;\n    padding: 20px;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  }\n  \n  .article-management h2 {\n    margin-bottom: 20px;\n  }\n  \n  .article-table {\n    width: 100%;\n    border-collapse: collapse;\n  }\n  \n  .article-table th, .article-table td {\n    padding: 12px;\n    border: 1px solid #ddd;\n  }\n  \n  .article-table th {\n    background-color: orange;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
