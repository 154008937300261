// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.back-to-top {
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 1000;
  }
  
  .back-to-top-button {
    display: inline-block;
    padding: 10px 15px;
    font-size: 16px;
    color: #5D22EE;
    background-color: #e7e04b;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
    opacity: 0.8;
  }
  
  .back-to-top-button:hover {
    opacity: 1;
    background-color: e7e04b;

  }
  `, "",{"version":3,"sources":["webpack://./src/components/backToTopButton.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,WAAW;IACX,aAAa;EACf;;EAEA;IACE,qBAAqB;IACrB,kBAAkB;IAClB,eAAe;IACf,cAAc;IACd,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,oCAAoC;IACpC,YAAY;EACd;;EAEA;IACE,UAAU;IACV,wBAAwB;;EAE1B","sourcesContent":[".back-to-top {\n    position: fixed;\n    bottom: 40px;\n    right: 40px;\n    z-index: 1000;\n  }\n  \n  .back-to-top-button {\n    display: inline-block;\n    padding: 10px 15px;\n    font-size: 16px;\n    color: #5D22EE;\n    background-color: #e7e04b;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    transition: opacity 0.3s ease-in-out;\n    opacity: 0.8;\n  }\n  \n  .back-to-top-button:hover {\n    opacity: 1;\n    background-color: e7e04b;\n\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
