import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Calendar from './Calendar';
import WelcomeBanner from './WelcomeBanner';
import './dashboard.css';

const Dashboard = () => {
  const [showWelcomeBanner, setShowWelcomeBanner] = useState(false);

  useEffect(() => {
    const userHasSeenWelcomeBanner = localStorage.getItem('userHasSeenWelcomeBanner');
    if (!userHasSeenWelcomeBanner) {
      setShowWelcomeBanner(true);
      localStorage.setItem('userHasSeenWelcomeBanner', 'true');
    }
  }, []);

  return (
    <div className="dashboard-container">
      {showWelcomeBanner && <WelcomeBanner onClose={() => setShowWelcomeBanner(false)} />}
      <div className="calendar-container">
        <Calendar />
      </div>
      <div className="widget-container">
        <div className="dashboard-objectif-container">
          <Link to="/objectif">Mon Objectif Minceur 🎯 </Link>
        </div>
        <div className="suivi-container">
          <Link to="/mesindicateurs">Mon Check-Up 🧐
          </Link>
        </div>
        <div className="widget4-container">
          <p>Mon Tableau de Vision 🔮</p>
        </div>
        <div className="widget5-container">
          <p> Mon Journal 🖋️</p>
        </div>
        <div className="widget6-container">
          <p>Get Together 🤝</p>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
