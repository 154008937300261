import React from 'react';

const WelcomeBanner = ({ onClose, onAction }) => {
  return (
    <div className="welcome-banner">
      <p>Bienvenue ! Merci d'utiliser notre application.</p>
      <button onClick={onClose}>Fermer</button>
      <button onClick={onAction}>Commencer</button>
    </div>
  );
};

export default WelcomeBanner;
