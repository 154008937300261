// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-management {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .user-management h2 {
    margin-bottom: 20px;
  }
  
  .user-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .user-table th, .user-table td {
    padding: 12px;
    border: 1px solid #ddd;
  }
  
  .user-table th {
    background: lightblue;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/admin/styles/userManagement.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,kBAAkB;IAClB,wCAAwC;EAC1C;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,yBAAyB;EAC3B;;EAEA;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,qBAAqB;EACvB","sourcesContent":[".user-management {\n    background: #fff;\n    padding: 20px;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  }\n  \n  .user-management h2 {\n    margin-bottom: 20px;\n  }\n  \n  .user-table {\n    width: 100%;\n    border-collapse: collapse;\n  }\n  \n  .user-table th, .user-table td {\n    padding: 12px;\n    border: 1px solid #ddd;\n  }\n  \n  .user-table th {\n    background: lightblue;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
