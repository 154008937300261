import React from "react";
import "./pagination.css";

const Pagination = ({ page, totalPages, onPageChange }) => {
  const handleNext = () => {
    if (page < totalPages) onPageChange(page + 1);
  };

  const handlePrevious = () => {
    if (page > 1) onPageChange(page - 1);
  };

  return (
    <div className="pagination">
      <button className="arrow" onClick={handlePrevious} disabled={page === 1}>
        &laquo;
      </button>
      {page > 2 && (
        <span className="page-number" onClick={() => onPageChange(1)}>
          1
        </span>
      )}
      {page > 3 && <span className="dots">...</span>}
      {page > 1 && (
        <span className="page-number" onClick={() => onPageChange(page - 1)}>
          {page - 1}
        </span>
      )}
      <span className="page-number current">{page}</span>
      {page < totalPages && (
        <span className="page-number" onClick={() => onPageChange(page + 1)}>
          {page + 1}
        </span>
      )}
      {page < totalPages - 2 && <span className="dots">...</span>}
      {page < totalPages - 1 && (
        <span className="page-number" onClick={() => onPageChange(totalPages)}>
          {totalPages}
        </span>
      )}
      <button className="arrow" onClick={handleNext}>
        &raquo;
      </button>
    </div>
  );
};

export default Pagination;
