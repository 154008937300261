// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    background-color: #fcfcfc;
  }
  
  .reset-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .form-container {
    width: 400px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
  }
  
  .reset-password-title {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .reset-password-label {
    display: block;
    margin-bottom: 5px;
  }
  
  .reset-password-input {
    width: calc(100% - 20px);
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin: 0 auto;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  button {
    width: 100%;
    padding: 10px;
    background-color: #e7e04b;
    color: #5D22EE;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #463d3d;
  }
  
  .error-message {
    color: red;
    margin-bottom: 10px;
    text-align: center;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/password/resetPassword.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,kCAAkC;IAClC,yBAAyB;EAC3B;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;EACf;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,wCAAwC;IACxC,cAAc;EAChB;;EAEA;IACE,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,cAAc;IACd,kBAAkB;EACpB;;EAEA;IACE,wBAAwB;IACxB,aAAa;IACb,kBAAkB;IAClB,sBAAsB;IACtB,cAAc;EAChB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,yBAAyB;IACzB,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,UAAU;IACV,mBAAmB;IACnB,kBAAkB;EACpB","sourcesContent":["body {\n    margin: 0;\n    padding: 0;\n    font-family: 'Poppins', sans-serif;\n    background-color: #fcfcfc;\n  }\n  \n  .reset-password-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n  }\n  \n  .form-container {\n    width: 400px;\n    padding: 20px;\n    background-color: #fff;\n    border-radius: 8px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n    margin: 0 auto;\n  }\n  \n  .reset-password-title {\n    margin-bottom: 20px;\n    text-align: center;\n  }\n  \n  .reset-password-label {\n    display: block;\n    margin-bottom: 5px;\n  }\n  \n  .reset-password-input {\n    width: calc(100% - 20px);\n    padding: 10px;\n    border-radius: 4px;\n    border: 1px solid #ccc;\n    margin: 0 auto;\n  }\n  \n  .form-group {\n    margin-bottom: 20px;\n  }\n  \n  button {\n    width: 100%;\n    padding: 10px;\n    background-color: #e7e04b;\n    color: #5D22EE;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n  }\n  \n  button:hover {\n    background-color: #463d3d;\n  }\n  \n  .error-message {\n    color: red;\n    margin-bottom: 10px;\n    text-align: center;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
