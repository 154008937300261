/* import React, { useState, useEffect } from 'react';
import trackerService from '../services/trackerService';
import userTrackerEntriesService from '../services/userTrackerEntriesService';
import './sleepQualityTracker.css';

const SleepQualityTracker = () => {
  const [qualityScore, setQualityScore] = useState(5);
  const [wokeUp, setWokeUp] = useState(false);
  const [fellBackAsleep, setFellBackAsleep] = useState(false);
  const [rested, setRested] = useState(true);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [sleepQualityEntries, setSleepQualityEntries] = useState([]);
  const userId = sessionStorage.getItem('userId').replace(/"/g, '');

  const handleAddSleepQuality = async () => {
    try {
      const response = await trackerService.addSleepQuality(userId, qualityScore, wokeUp, fellBackAsleep, rested);
      if (response.success) {
        setMessage('Entry added successfully');
        setError(null);
        fetchSleepQualityEntries();
      } else {
        setError('Error adding sleep quality entry');
        setMessage(null);
      }
    } catch (error) {
      console.error('Error adding sleep quality entry:', error);
      setError('Error adding sleep quality entry');
      setMessage(null);
    }
  };

  const fetchSleepQualityEntries = async () => {
    try {
      const entries = await userTrackerEntriesService.getUserQualitySleepEntries(userId);
      setSleepQualityEntries(entries || []);
      setError(null);
    } catch (error) {
      console.error('Error fetching sleep quality entries:', error);
      setError('Error fetching sleep quality entries');
      setSleepQualityEntries([]);
    }
  };

  useEffect(() => {
    fetchSleepQualityEntries();
  }, []);

  return (
    <div className="sleep-quality-tracker-container">
      <h1>Suivi de Qualité de Sommeil</h1>
      {error && <div className="error">{error}</div>}
      {message && <div className="message">{message}</div>}
      <div className="quality-score-container">
        <h2>J'évalue : {qualityScore}/10</h2>
        <input
          type="range"
          min="1"
          max="10"
          value={qualityScore}
          onChange={(e) => setQualityScore(Number(e.target.value))}
        />
      </div>
      <div className="boolean-inputs-container">
        <div>
          <label>Réveil Durant La Nuit</label>
          <input
            type="checkbox"
            checked={wokeUp}
            onChange={(e) => setWokeUp(e.target.checked)}
          />
        </div>
        <div>
          <label>J'ai réussi à me Rendormir</label>
          <input
            type="checkbox"
            checked={fellBackAsleep}
            onChange={(e) => setFellBackAsleep(e.target.checked)}
          />
        </div>
        <div>
          <label>Je me Sens Reposé(e)</label>
          <input
            type="checkbox"
            checked={rested}
            onChange={(e) => setRested(e.target.checked)}
          />
        </div>
      </div>
      <button className="submit-button" onClick={handleAddSleepQuality}>
        Submit
      </button>
      <div className="sleep-quality-entries-container">
        <h2>Qualité de Mes Dernières Nuits </h2>
        {sleepQualityEntries.length === 0 ? (
          <p>Vous n'avez pas assez de données à afficher.</p>
        ) : (
          <ul>
            {sleepQualityEntries.map((entry) => (
              <li key={entry._id}>
                Score: {entry.qualityScore}/10, Réveil Nocturne: {entry.wokeUp ? 'Oui' : 'Non'}, Rendormi(e): {entry.fellBackAsleep ? 'Oui' : 'Non'}, Reposé: {entry.rested ? 'Oui' : 'Non'}, Date: {new Date(entry.date).toLocaleString()}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default SleepQualityTracker;
 */

import React, { useState, useEffect } from 'react';
import trackerService from '../services/trackerService';
import userTrackerEntriesService from '../services/userTrackerEntriesService';
import './sleepQualityTracker.css';

const SleepQualityTracker = () => {
  const [qualityScore, setQualityScore] = useState(5);
  const [wokeUp, setWokeUp] = useState(false);
  const [fellBackAsleep, setFellBackAsleep] = useState(false);
  const [rested, setRested] = useState(true);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [sleepQualityEntries, setSleepQualityEntries] = useState([]);

  const handleAddSleepQuality = async () => {
    try {
      const response = await trackerService.addSleepQuality(qualityScore, wokeUp, fellBackAsleep, rested);
      if (response.success) {
        setMessage('Entry added successfully');
        setError(null);
        fetchSleepQualityEntries();
      } else {
        setError('Error adding sleep quality entry');
        setMessage(null);
      }
    } catch (error) {
      console.error('Error adding sleep quality entry:', error);
      setError('Error adding sleep quality entry');
      setMessage(null);
    }
  };

  const fetchSleepQualityEntries = async () => {
    try {
      const entries = await userTrackerEntriesService.getUserQualitySleepEntries();
      setSleepQualityEntries(entries || []);
      setError(null);
    } catch (error) {
      console.error('Error fetching sleep quality entries:', error);
      setError('Error fetching sleep quality entries');
      setSleepQualityEntries([]);
    }
  };

  useEffect(() => {
    fetchSleepQualityEntries();
  }, []);

  return (
    <div className="sleep-quality-tracker-container">
      <h1>Suivi de Qualité de Sommeil</h1>
      {error && <div className="error">{error}</div>}
      {message && <div className="message">{message}</div>}
      <div className="quality-score-container">
        <h2>J'évalue : {qualityScore}/10</h2>
        <input
          type="range"
          min="1"
          max="10"
          value={qualityScore}
          onChange={(e) => setQualityScore(Number(e.target.value))}
        />
      </div>
      <div className="boolean-inputs-container">
        <div>
          <label>Réveil Durant La Nuit</label>
          <input
            type="checkbox"
            checked={wokeUp}
            onChange={(e) => setWokeUp(e.target.checked)}
          />
        </div>
        <div>
          <label>J'ai réussi à me Rendormir</label>
          <input
            type="checkbox"
            checked={fellBackAsleep}
            onChange={(e) => setFellBackAsleep(e.target.checked)}
          />
        </div>
        <div>
          <label>Je me Sens Reposé(e)</label>
          <input
            type="checkbox"
            checked={rested}
            onChange={(e) => setRested(e.target.checked)}
          />
        </div>
      </div>
      <button className="submit-button" onClick={handleAddSleepQuality}>
        Submit
      </button>
      <div className="sleep-quality-entries-container">
        <h2>Qualité de Mes Dernières Nuits </h2>
        {sleepQualityEntries.length === 0 ? (
          <p>Vous n'avez pas assez de données à afficher.</p>
        ) : (
          <ul>
            {sleepQualityEntries.map((entry) => (
              <li key={entry._id}>
                Score: {entry.qualityScore}/10, Réveil Nocturne: {entry.wokeUp ? 'Oui' : 'Non'}, Rendormi(e): {entry.fellBackAsleep ? 'Oui' : 'Non'}, Reposé: {entry.rested ? 'Oui' : 'Non'}, Date: {new Date(entry.date).toLocaleString()}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default SleepQualityTracker;
