// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 1px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .article h2:hover {
  color: var(--secondary-color);
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .pagination button, .pagination span.page-number {
    background-color: #fff;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .pagination button:disabled, .pagination span.page-number.current {
    background-color: var(--primary-color);
    color: #fff;
    cursor: not-allowed;
  }
  
  .pagination button:hover:not(:disabled) {
    background-color: var(--secondary-color);
    color: #fff;
  }`, "",{"version":3,"sources":["webpack://./src/components/usersList.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,cAAc;EACd,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,wCAAwC;EACxC;;EAEA;EACA,6BAA6B;AAC/B;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,sBAAsB;IACtB,sCAAsC;IACtC,2BAA2B;IAC3B,kBAAkB;IAClB,kBAAkB;IAClB,qBAAqB;IACrB,qBAAqB;IACrB,eAAe;IACf,eAAe;IACf,eAAe;IACf,kBAAkB;IAClB,sCAAsC;EACxC;;EAEA;IACE,sCAAsC;IACtC,WAAW;IACX,mBAAmB;EACrB;;EAEA;IACE,wCAAwC;IACxC,WAAW;EACb","sourcesContent":[".user-container {\n  max-width: 800px;\n  margin: 0 auto;\n  padding: 1px;\n  background-color: #f9f9f9;\n  border-radius: 8px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  }\n\n  .article h2:hover {\n  color: var(--secondary-color);\n}\n\n.pagination {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-top: 20px;\n  }\n  \n  .pagination button, .pagination span.page-number {\n    background-color: #fff;\n    border: 1px solid var(--primary-color);\n    color: var(--primary-color);\n    padding: 10px 20px;\n    text-align: center;\n    text-decoration: none;\n    display: inline-block;\n    font-size: 16px;\n    margin: 4px 2px;\n    cursor: pointer;\n    border-radius: 4px;\n    transition: background-color 0.3s ease;\n  }\n  \n  .pagination button:disabled, .pagination span.page-number.current {\n    background-color: var(--primary-color);\n    color: #fff;\n    cursor: not-allowed;\n  }\n  \n  .pagination button:hover:not(:disabled) {\n    background-color: var(--secondary-color);\n    color: #fff;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
