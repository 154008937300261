// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sleep-tracker-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 2px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .sleep-tracker-container h1,
  .sleep-tracker-container h2 {
    text-align: center;
    font-size: 36px;
    margin-bottom: 40px;
    font-weight: 400;
    color: var(--primary-color);
  }
  
  .error,
  .message {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .buttons-container {
    display: flex;
    justify-content: space-around;
  }
  
  .sleep-button {
  /*   background-color: var(--primary-color);
    color: var(--primary-color); */
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .sleep-button:hover {
    background-color: var(--secondary-color);
  }
  
  .sleep-entries-container {
    margin-top: 20px;
  }
  
  .sleep-entries-container ul {
    list-style-type: none;
    padding: 0;
  }
  
  .sleep-entries-container li {
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/sleepTracker.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,kBAAkB;IAClB,sBAAsB;IACtB,uCAAuC;EACzC;;EAEA;;IAEE,kBAAkB;IAClB,eAAe;IACf,mBAAmB;IACnB,gBAAgB;IAChB,2BAA2B;EAC7B;;EAEA;;IAEE,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,6BAA6B;EAC/B;;EAEA;EACA;kCACgC;IAC9B,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,kBAAkB;IAClB,sCAAsC;EACxC;;EAEA;IACE,wCAAwC;EAC1C;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,qBAAqB;IACrB,UAAU;EACZ;;EAEA;IACE,aAAa;IACb,6BAA6B;EAC/B","sourcesContent":[".sleep-tracker-container {\n    max-width: 800px;\n    margin: 0 auto;\n    padding: 20px;\n    border-radius: 2px;\n    background-color: #fff;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  }\n  \n  .sleep-tracker-container h1,\n  .sleep-tracker-container h2 {\n    text-align: center;\n    font-size: 36px;\n    margin-bottom: 40px;\n    font-weight: 400;\n    color: var(--primary-color);\n  }\n  \n  .error,\n  .message {\n    text-align: center;\n    margin-bottom: 20px;\n  }\n  \n  .buttons-container {\n    display: flex;\n    justify-content: space-around;\n  }\n  \n  .sleep-button {\n  /*   background-color: var(--primary-color);\n    color: var(--primary-color); */\n    border: none;\n    padding: 10px 20px;\n    font-size: 16px;\n    cursor: pointer;\n    border-radius: 4px;\n    transition: background-color 0.3s ease;\n  }\n  \n  .sleep-button:hover {\n    background-color: var(--secondary-color);\n  }\n  \n  .sleep-entries-container {\n    margin-top: 20px;\n  }\n  \n  .sleep-entries-container ul {\n    list-style-type: none;\n    padding: 0;\n  }\n  \n  .sleep-entries-container li {\n    padding: 10px;\n    border-bottom: 1px solid #ccc;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
