// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumb {
    padding: 10px;
    background-color: #f5f5f5;
    margin: 10px 0;
  }
  
  .breadcrumb a {
    text-decoration: none;
    color: #333;
  }
  
  .breadcrumb a:hover {
    text-decoration: underline;
  }
  
  .breadcrumb span {
    color: #999;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/breadcrumb.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,cAAc;EAChB;;EAEA;IACE,qBAAqB;IACrB,WAAW;EACb;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,WAAW;EACb","sourcesContent":[".breadcrumb {\n    padding: 10px;\n    background-color: #f5f5f5;\n    margin: 10px 0;\n  }\n  \n  .breadcrumb a {\n    text-decoration: none;\n    color: #333;\n  }\n  \n  .breadcrumb a:hover {\n    text-decoration: underline;\n  }\n  \n  .breadcrumb span {\n    color: #999;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
