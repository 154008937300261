import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './usersList.css';
import userService from '../services/userService';
import Pagination from './Pagination';

const UsersList = () => {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 10;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const data = await userService.getAllUsers(page, limit);
        setUsers(data.users);
        setTotalPages(data.pages);
      } catch (error) {
        console.error('Erreur lors de la récupération des utilisateurs:', error);
      }
    };

    fetchUsers();
  }, [page, limit]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <div>
      <h1>Liste de tous les utilisateurs</h1>
      <div>
        {users.map(user => (
          <div className='user-container' key={user._id}>  
            <div className='user-container-three'>
              <h2>
                <Link to={`/usersdetails/${user._id}`}>⚀ {user.email} - {user._id} 🖉</Link>
              </h2>
            </div>
          </div>
        ))}
      </div>
      <Pagination page={page} totalPages={totalPages} onPageChange={handlePageChange} />
    </div>
  );
};

export default UsersList;
