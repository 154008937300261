import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import articleService from '../../services/articleService';
import Pagination from '../Pagination';
import './styles/articleManagement.css';
import ArticleForm from '../ArticleForm';

const ArticleManagement = () => {
  const [articles, setArticles] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 10;

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const data = await articleService.getArticles(page, limit);
        setArticles(data.articles || []);
        setTotalPages(data.pages);
      } catch (error) {
        console.error('Erreur lors de la récupération des articles:', error);
      }
    };

    fetchArticles();
  }, [page]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <div className='article-management'>
      <Link to="/create/article">Articles</Link>
      <h2>Blog</h2>
      <table className="article-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>TITLE</th>
            <th>CREATED AT</th>
            <th>UPDATED AT</th>
          </tr>
        </thead>
        <tbody>
          { articles.length > 0 ? (
            articles.map(article => (
              <tr key={article._id}>
                <td>{article._id}</td>
                <td>{article.title}</td>
                <td>{article.date}</td>
                <td>{article.updatedAt}</td>

              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4">No article found</td>
            </tr>
          )}
        </tbody>
      </table>
      <Pagination page={page} totalPages={totalPages} onPageChange={handlePageChange} />
    </div>
  );
};

export default ArticleManagement;
