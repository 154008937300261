// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .page-number,
  .arrow {
    cursor: pointer;
    margin: 0 5px;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  .arrow:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  
  .page-number.current {
    font-weight: bold;
    color: white;
    background-color: #007bff;
  }
  
  .page-number:hover,
  .arrow:hover {
    background-color: #f1f1f1;
  }
  
  .dots {
    margin: 0 5px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/pagination.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;;IAEE,eAAe;IACf,aAAa;IACb,iBAAiB;IACjB,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,mBAAmB;IACnB,YAAY;EACd;;EAEA;IACE,iBAAiB;IACjB,YAAY;IACZ,yBAAyB;EAC3B;;EAEA;;IAEE,yBAAyB;EAC3B;;EAEA;IACE,aAAa;EACf","sourcesContent":[".pagination {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-top: 20px;\n  }\n  \n  .page-number,\n  .arrow {\n    cursor: pointer;\n    margin: 0 5px;\n    padding: 5px 10px;\n    border: 1px solid #ccc;\n    border-radius: 3px;\n  }\n  \n  .arrow:disabled {\n    cursor: not-allowed;\n    opacity: 0.5;\n  }\n  \n  .page-number.current {\n    font-weight: bold;\n    color: white;\n    background-color: #007bff;\n  }\n  \n  .page-number:hover,\n  .arrow:hover {\n    background-color: #f1f1f1;\n  }\n  \n  .dots {\n    margin: 0 5px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
