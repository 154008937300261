import { notifyError } from './toastService';

export const handleAxiosError = (error) => {
  if (error.response) {
    notifyError(error.response.data.message || 'Une erreur est survenue sur le serveur.');
  } else if (error.request) {
    notifyError('Erreur de connexion. Veuillez vérifier votre réseau.');
  } else {
    notifyError('Une erreur est survenue. Veuillez réessayer.');
  }
};

const errorService = {
  handleAxiosError,
};

export default errorService;
