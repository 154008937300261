// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.objectif-container {
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(229, 200, 70, 0.377);
    margin: 20px auto;
    max-width: 600px;
  }
  
  .objectif-container h2 {
    color: var(--primary-color);
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .objectif-container form {
    display: flex;
    flex-direction: column;
  }
  
  .objectif-container label {
    display: flex;
    align-items: center;
    font-size: 18px;
  }
  
  .objectif-container input {
    margin-left: 10px;
    margin-bottom: 10px;
    padding: 10px;
    color: rgb(134, 39, 4);
    font-size: 16px;
    flex: 1 1;
  }
  
  .objectif-container button {
    padding: 10px;
    color: #fff;
    background-color: var(--primary-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .objectif-container button:hover {
    background-color: var(--secondary-color);
  }
  
  .objectif-container p {
    font-size: 16px;
    margin-top: 10px;
  }
  .durée {
    border:none;
    -webkit-text-decoration: solid;
            text-decoration: solid;
  }
  
  .kilos{
    border: thin;
  }
  .objectif-form-container {
    border: double rgba(252, 245, 191, 0.467) 10px;
    padding: 3%;
  }`, "",{"version":3,"sources":["webpack://./src/components/objectif.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,+CAA+C;IAC/C,iBAAiB;IACjB,gBAAgB;EAClB;;EAEA;IACE,2BAA2B;IAC3B,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,eAAe;EACjB;;EAEA;IACE,iBAAiB;IACjB,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,eAAe;IACf,SAAO;EACT;;EAEA;IACE,aAAa;IACb,WAAW;IACX,sCAAsC;IACtC,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,sCAAsC;EACxC;;EAEA;IACE,wCAAwC;EAC1C;;EAEA;IACE,eAAe;IACf,gBAAgB;EAClB;EACA;IACE,WAAW;IACX,8BAAsB;YAAtB,sBAAsB;EACxB;;EAEA;IACE,YAAY;EACd;EACA;IACE,8CAA8C;IAC9C,WAAW;EACb","sourcesContent":[".objectif-container {\n    padding: 20px;\n    background-color: #fff;\n    border-radius: 5px;\n    box-shadow: 0 2px 4px rgba(229, 200, 70, 0.377);\n    margin: 20px auto;\n    max-width: 600px;\n  }\n  \n  .objectif-container h2 {\n    color: var(--primary-color);\n    font-size: 24px;\n    margin-bottom: 20px;\n  }\n  \n  .objectif-container form {\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .objectif-container label {\n    display: flex;\n    align-items: center;\n    font-size: 18px;\n  }\n  \n  .objectif-container input {\n    margin-left: 10px;\n    margin-bottom: 10px;\n    padding: 10px;\n    color: rgb(134, 39, 4);\n    font-size: 16px;\n    flex: 1;\n  }\n  \n  .objectif-container button {\n    padding: 10px;\n    color: #fff;\n    background-color: var(--primary-color);\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n  }\n  \n  .objectif-container button:hover {\n    background-color: var(--secondary-color);\n  }\n  \n  .objectif-container p {\n    font-size: 16px;\n    margin-top: 10px;\n  }\n  .durée {\n    border:none;\n    text-decoration: solid;\n  }\n  \n  .kilos{\n    border: thin;\n  }\n  .objectif-form-container {\n    border: double rgba(252, 245, 191, 0.467) 10px;\n    padding: 3%;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
