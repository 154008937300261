import React, { useState } from 'react';

const LikeButton = ({ articleId }) => {
  const [likes, setLikes] = useState(0);

  const handleLike = async () => {
    try {
      const response = await fetch(`https://globoal.fr/api/blog/article/like/${articleId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
      });
      if (!response.ok) {
        throw new Error('Failed to like article');
      }
      const data = await response.json();
      setLikes(data.article.likes); 
    } catch (error) {
      console.error('Error liking article:', error);
    }
  };

  return (
    <div>
      <button onClick={handleLike}>J'ai trouvé cela intéressant 👍</button>
{/*       <span>Likes: {likes}</span>
 */}    </div>
  );
};

export default LikeButton;
