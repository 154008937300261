import React from 'react';
import './MesIndicateurs.css'
import MoodIndicator from './MoodIndicator';
import WaterConsumption from './WaterConsumption.js';
import SleepTracker from './SleepTracker.js';
import SleepQualityIndicator from './SleepQualityIndicator.js'
import WeightTracker from './indicators/WeightTracker.js'
const YourComponent = () => {
  return (
    <div className="my-indicators-container">
      <div className="component-section">
        <MoodIndicator />
      </div>
      <div className="component-section">
        <WaterConsumption />
      </div>
      <div className="component-section">
        <SleepTracker />
      </div>
      <div className="component-section">
        <SleepQualityIndicator />
      </div> 
      <div className="component-section">
        <WeightTracker />
      </div>
    </div>
  );
};

export default YourComponent;
