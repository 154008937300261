import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getArticles } from "../services/articleService";
import Pagination from "./Pagination";
import "./blog.css";

const Blog = () => {
  const [articles, setArticles] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 10;

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const data = await getArticles(page, limit);
        setArticles(data.articles);
        setTotalPages(data.pages);
      } catch (error) {
        console.error("Erreur lors de la récupération des articles:", error);
      }
    };

    fetchArticles();
  }, [page]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <div className="blog-container">
      <div>
        <h1>TOUS LES ARTICLES</h1>
        {articles.map((article) => (
          <div className="article" key={article._id}>
            <Link to={`/article/details/${article.slug}`}>
              {article.imageUrl && (
                <img
                  src={article.imageUrl}
                  alt={article.title}
                  className="article-image"
                />
              )}
              <h2>{article.title}</h2>
              <div>
                <small>
                  <strong>👍</strong> {article.likes}
                </small>
              </div>
            </Link>
            <Link
              to={`/article/details/${article.slug}`}
              className="read-more-link"
            >
              →
            </Link>
          </div>
        ))}
      </div>
      <div className="pagination">
        <Pagination
          page={page}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default Blog;
