import React, { useState } from "react";
import axios from "../../services/axiosConfig"; 
import "./contact.css";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: ""
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await axios.post("/contact", formData);

      setFormData({ name: "", email: "", message: "" });
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="contact-container">
      <h1>Contactez-nous</h1>
      <p>Une suggestion ? Une question ? Une remarque ? Contactez-nous via ce formulaire, et nous vous répondrons rapidement.</p>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Nom:*</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="email">Email:*</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="message">Message:*</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" disabled={loading}>
          {loading ? "Envoi en cours..." : "Envoyer"}
        </button>
      </form>
    </div>
  );
};

export default Contact;
