/* import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import './articleForm.css';
import { useParams } from 'react-router-dom';

const ArticleDetails = () => {
    const {id} = useParams();
  const [title, setTitle] = useState('');
  const [sections, setSections] = useState([{ heading: '', level: 1, content: [{ type: 'paragraph', text: '' }] }]);
  const [imageFile, setImageFile] = useState(null);
  const [category, setCategory] = useState('');
  const [tags, setTags] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [metaDescription, setMetaDescription] = useState('');
  const [metaRobotsTags, setMetaRobotsTags] = useState([]);
  const [author, setAuthor] = useState('');

  useEffect(() => {
    const fetchArticleData = async () => {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`http://localhost:5000/api/blog/article/byid/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const data = await response.json();
      setTitle(data.title);
      setSections(data.sections);
      setCategory(data.category);
      setTags(data.tags);
      setKeywords(data.keywords);
      setMetaDescription(data.metaDescription);
      setMetaRobotsTags(data.metaRobotsTags);
      setAuthor(data.author);
    };

    fetchArticleData();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let imageUrl = null;
    if (imageFile) {
      const formData = new FormData();
      formData.append('image', imageFile);

      const token = sessionStorage.getItem('token');

      const imageResponse = await fetch('http://localhost:5000/api/files/upload', {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const imageData = await imageResponse.json();
      imageUrl = imageData.url;
    }

    const articleData = {
      title,
      sections,
      category,
      tags,
      keywords,
      metaDescription,
      metaRobotsTags,
      author,
      imageUrl 
    };

    const token = sessionStorage.getItem('token');

    const response = await fetch(`http://localhost:5000/api/blog/article/update/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(articleData)
    });

    const data = await response.json();
    console.log(data);
  };

  const handleSectionChange = (index, key, value) => {
    const updatedSections = [...sections];
    updatedSections[index][key] = value;
    setSections(updatedSections);
  };

  const handleContentChange = (sectionIndex, contentIndex, key, value) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].content[contentIndex][key] = value;
    setSections(updatedSections);
  };

  const addSection = () => {
    setSections([...sections, { heading: '', level: 1, content: [{ type: 'paragraph', text: '' }] }]);
  };

  const addContent = (sectionIndex) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].content.push({ type: 'paragraph', text: '' });
    setSections(updatedSections);
  };

  const deleteSection = (index) => {
    const updatedSections = [...sections];
    updatedSections.splice(index, 1);
    setSections(updatedSections);
  };

  const deleteContent = (sectionIndex, contentIndex) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].content.splice(contentIndex, 1);
    setSections(updatedSections);
  };

  const cancelForm = () => {
    setTitle('');
    setSections([{ heading: '', level: 1, content: [{ type: 'paragraph', text: '' }] }]);
    setImageFile(null);
    setCategory('');
    setTags([]);
    setKeywords([]);
    setMetaDescription('');
    setMetaRobotsTags([]);
    setAuthor('');
  };

  return (
    <div className="article-form-container">
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Title"
          className="input-title"
        />
        {sections.map((section, index) => (
          <div key={index}>
            <input
              type="text"
              value={section.heading}
              onChange={(e) => handleSectionChange(index, 'heading', e.target.value)}
              placeholder="Heading"
              className="input-heading"
            />
            <select
              value={section.level}
              onChange={(e) => handleSectionChange(index, 'level', parseInt(e.target.value))}
              className="select-level"
            >
              <option value={1}>Heading 1</option>
              <option value={2}>Heading 2</option>
              <option value={3}>Heading 3</option>
              <option value={4}>Heading 4</option>
              <option value={5}>Heading 5</option>
              <option value={6}>Heading 6</option>
            </select>
            {section.content.map((contentItem, contentIndex) => (
              <div key={contentIndex}>
                <textarea
                  value={contentItem.text}
                  onChange={(e) => handleContentChange(index, contentIndex, 'text', e.target.value)}
                  placeholder="Content"
                  className="textarea-content"
                ></textarea>
                <button type="button" onClick={() => deleteContent(index, contentIndex)}>Delete Content</button>
              </div>
            ))}
            <button type="button" onClick={() => addContent(index)}>Add Content</button>
            <button type="button" onClick={() => deleteSection(index)}>Delete Section</button>
          </div>
        ))}
        <button type="button" onClick={addSection}>Add Section</button>
        <input
          type="file"
          onChange={(e) => setImageFile(e.target.files[0])}
          className="input-file"
          name="image"
        />
        <input
          type="text"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          placeholder="Category"
          className="input-category"
        />
        <input
          type="text"
          value={tags.join(', ')}
          onChange={(e) => setTags(e.target.value.split(',').map(tag => tag.trim()))}
          placeholder="Tags (comma separated)"
          className="input-tags"
        />
        <input
          type="text"
          value={keywords.join(', ')}
          onChange={(e) => setKeywords(e.target.value.split(',').map(keyword => keyword.trim()))}
          placeholder="Keywords (comma separated)"
          className="input-keywords"
        />
        <textarea
          value={metaDescription}
          onChange={(e) => setMetaDescription(e.target.value)}
          placeholder="Meta Description"
          className="textarea-meta-description"
        ></textarea>
        <input
          type="text"
          value={metaRobotsTags.join(', ')}
          onChange={(e) => setMetaRobotsTags(e.target.value.split(',').map(tag => tag.trim()))}
          placeholder="Meta Robots Tags (comma separated)"
          className="input-meta-robots-tags"
        />
        <input
          type="text"
          value={author}
          onChange={(e) => setAuthor(e.target.value)}
          placeholder="Author"
          className="input-author"
        />
        <button type="submit" className="submit-button">Update</button>
        <button type="button" onClick={cancelForm}>Cancel</button>
      </form>
      <div className="preview-container">
        <h3>Preview</h3>
        <h2>{title}</h2>
        <p>Category: {category}</p>
        <p>Tags: {tags.join(', ')}</p>
        <p>Keywords: {keywords.join(', ')}</p>
        <p>Meta Description: {metaDescription}</p>
        <p>Meta Robots Tags: {metaRobotsTags.join(', ')}</p>
        <p>Author: {author}</p>
        {sections.map(section => (
          <div key={section.heading}>
            {section.level === 1 && <h3>{section.heading}</h3>}
            {section.level === 2 && <h4>{section.heading}</h4>}
            {section.level === 3 && <h5>{section.heading}</h5>}
            {section.level === 4 && <h6>{section.heading}</h6>}
            {section.content.map(contentItem => (
              <ReactMarkdown key={contentItem.text}>{contentItem.text}</ReactMarkdown>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ArticleDetails;
 */

// src/components/ArticleDetails.js
import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import { getArticleById, uploadImage, updateArticle } from '../services/articleService';
import './articleForm.css';

const ArticleDetails = () => {
  const { id } = useParams();
  const [title, setTitle] = useState('');
  const [sections, setSections] = useState([{ heading: '', level: 1, content: [{ type: 'paragraph', text: '' }] }]);
  const [imageFile, setImageFile] = useState(null);
  const [category, setCategory] = useState('');
  const [tags, setTags] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [metaDescription, setMetaDescription] = useState('');
  const [metaRobotsTags, setMetaRobotsTags] = useState([]);
  const [author, setAuthor] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchArticleData = async () => {
      try {
        const data = await getArticleById(id);
        setTitle(data.title);
        setSections(data.sections);
        setCategory(data.category);
        setTags(data.tags);
        setKeywords(data.keywords);
        setMetaDescription(data.metaDescription);
        setMetaRobotsTags(data.metaRobotsTags);
        setAuthor(data.author);
      } catch (error) {
        console.error('Erreur lors de la récupération de l\'article:', error);
        setErrorMessage('Erreur lors de la récupération de l\'article');
      }
    };

    fetchArticleData();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let imageUrl = null;
    if (imageFile) {
      try {
        const imageData = await uploadImage(imageFile);
        imageUrl = imageData.url;
      } catch (error) {
        console.error('Erreur lors du téléchargement de l\'image:', error);
        setErrorMessage('Erreur lors du téléchargement de l\'image');
        return;
      }
    }

    const articleData = {
      title,
      sections,
      category,
      tags,
      keywords,
      metaDescription,
      metaRobotsTags,
      author,
      imageUrl 
    };

    try {
      const data = await updateArticle(id, articleData);
      console.log(data);
      alert('Article mis à jour avec succès');
    } catch (error) {
      console.error('Erreur lors de la mise à jour de l\'article:', error);
      setErrorMessage('Erreur lors de la mise à jour de l\'article');
    }
  };

  const handleSectionChange = (index, key, value) => {
    const updatedSections = [...sections];
    updatedSections[index][key] = value;
    setSections(updatedSections);
  };

  const handleContentChange = (sectionIndex, contentIndex, key, value) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].content[contentIndex][key] = value;
    setSections(updatedSections);
  };

  const addSection = () => {
    setSections([...sections, { heading: '', level: 1, content: [{ type: 'paragraph', text: '' }] }]);
  };

  const addContent = (sectionIndex) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].content.push({ type: 'paragraph', text: '' });
    setSections(updatedSections);
  };

  const deleteSection = (index) => {
    const updatedSections = [...sections];
    updatedSections.splice(index, 1);
    setSections(updatedSections);
  };

  const deleteContent = (sectionIndex, contentIndex) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].content.splice(contentIndex, 1);
    setSections(updatedSections);
  };

  const cancelForm = () => {
    setTitle('');
    setSections([{ heading: '', level: 1, content: [{ type: 'paragraph', text: '' }] }]);
    setImageFile(null);
    setCategory('');
    setTags([]);
    setKeywords([]);
    setMetaDescription('');
    setMetaRobotsTags([]);
    setAuthor('');
    setErrorMessage('');
  };

  return (
    <div className="article-form-container">
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Title"
          className="input-title"
        />
        {sections.map((section, index) => (
          <div key={index}>
            <input
              type="text"
              value={section.heading}
              onChange={(e) => handleSectionChange(index, 'heading', e.target.value)}
              placeholder="Heading"
              className="input-heading"
            />
            <select
              value={section.level}
              onChange={(e) => handleSectionChange(index, 'level', parseInt(e.target.value))}
              className="select-level"
            >
              <option value={1}>Heading 1</option>
              <option value={2}>Heading 2</option>
              <option value={3}>Heading 3</option>
              <option value={4}>Heading 4</option>
              <option value={5}>Heading 5</option>
              <option value={6}>Heading 6</option>
            </select>
            {section.content.map((contentItem, contentIndex) => (
              <div key={contentIndex}>
                <textarea
                  value={contentItem.text}
                  onChange={(e) => handleContentChange(index, contentIndex, 'text', e.target.value)}
                  placeholder="Content"
                  className="textarea-content"
                ></textarea>
                <button type="button" onClick={() => deleteContent(index, contentIndex)}>Delete Content</button>
              </div>
            ))}
            <button type="button" onClick={() => addContent(index)}>Add Content</button>
            <button type="button" onClick={() => deleteSection(index)}>Delete Section</button>
          </div>
        ))}
        <button type="button" onClick={addSection}>Add Section</button>
        <input
          type="file"
          onChange={(e) => setImageFile(e.target.files[0])}
          className="input-file"
          name="image"
        />
        <input
          type="text"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          placeholder="Category"
          className="input-category"
        />
        <input
          type="text"
          value={tags.join(', ')}
          onChange={(e) => setTags(e.target.value.split(',').map(tag => tag.trim()))}
          placeholder="Tags (comma separated)"
          className="input-tags"
        />
        <input
          type="text"
          value={keywords.join(', ')}
          onChange={(e) => setKeywords(e.target.value.split(',').map(keyword => keyword.trim()))}
          placeholder="Keywords (comma separated)"
          className="input-keywords"
        />
        <textarea
          value={metaDescription}
          onChange={(e) => setMetaDescription(e.target.value)}
          placeholder="Meta Description"
          className="textarea-meta-description"
        ></textarea>
        <input
          type="text"
          value={metaRobotsTags.join(', ')}
          onChange={(e) => setMetaRobotsTags(e.target.value.split(',').map(tag => tag.trim()))}
          placeholder="Meta Robots Tags (comma separated)"
          className="input-meta-robots-tags"
        />
        <input
          type="text"
          value={author}
          onChange={(e) => setAuthor(e.target.value)}
          placeholder="Author"
          className="input-author"
        />
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <button type="submit" className="submit-button">Update</button>
        <button type="button" onClick={cancelForm}>Cancel</button>
      </form>
      <div className="preview-container">
        <h3>Preview</h3>
        <h2>{title}</h2>
        <p>Category: {category}</p>
        <p>Tags: {tags.join(', ')}</p>
        <p>Keywords: {keywords.join(', ')}</p>
        <p>Meta Description: {metaDescription}</p>
        <p>Meta Robots Tags: {metaRobotsTags.join(', ')}</p>
        <p>Author: {author}</p>
        {sections.map(section => (
          <div key={section.heading}>
            {section.level === 1 && <h3>{section.heading}</h3>}
            {section.level === 2 && <h4>{section.heading}</h4>}
            {section.level === 3 && <h5>{section.heading}</h5>}
            {section.level === 4 && <h6>{section.heading}</h6>}
            {section.content.map(contentItem => (
              <ReactMarkdown key={contentItem.text}>{contentItem.text}</ReactMarkdown>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ArticleDetails;
