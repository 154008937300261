// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.article-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
  }
  
  form {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
  }
  
  .input-title, .textarea-content, .input-file, .submit-button {
    margin-bottom: 15px;
  }
  
  .input-title, .textarea-content {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
  }
  
  .textarea-content {
    height: 150px;
  }
  
  .submit-button {
    padding: 10px;
    font-size: 16px;
    color: white;
    background-color: #007BFF;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  .preview-container {
    width: 100%;
    max-width: 600px;
    margin-top: 20px;
  }
  
  .preview-container h3 {
    margin-bottom: 10px;
  }
  
  .preview-container .markdown-preview {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/articleForm.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,YAAY;EACd;;EAEA;IACE,WAAW;IACX,gBAAgB;IAChB,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,eAAe;IACf,sBAAsB;IACtB,kBAAkB;IAClB,WAAW;EACb;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,aAAa;IACb,eAAe;IACf,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,WAAW;IACX,gBAAgB;IAChB,gBAAgB;EAClB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,yBAAyB;EAC3B","sourcesContent":[".article-form-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin: 20px;\n  }\n  \n  form {\n    width: 100%;\n    max-width: 600px;\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .input-title, .textarea-content, .input-file, .submit-button {\n    margin-bottom: 15px;\n  }\n  \n  .input-title, .textarea-content {\n    padding: 10px;\n    font-size: 16px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    width: 100%;\n  }\n  \n  .textarea-content {\n    height: 150px;\n  }\n  \n  .submit-button {\n    padding: 10px;\n    font-size: 16px;\n    color: white;\n    background-color: #007BFF;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n  }\n  \n  .submit-button:hover {\n    background-color: #0056b3;\n  }\n  \n  .preview-container {\n    width: 100%;\n    max-width: 600px;\n    margin-top: 20px;\n  }\n  \n  .preview-container h3 {\n    margin-bottom: 10px;\n  }\n  \n  .preview-container .markdown-preview {\n    padding: 10px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    background-color: #f9f9f9;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
