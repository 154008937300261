/* import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import './login.css'

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');


  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('http://localhost:5000/api/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
const responseData = await response.json();
console.log('Response Data:', responseData);

      if (!response.ok) {
        throw new Error(responseData.message || 'Erreur de connexion');
      }
      const { token, role, userId } = responseData; 

      sessionStorage.setItem('token', token);
      sessionStorage.setItem('userRole', role);
      sessionStorage.setItem('userId', userId);
      if (role === 'admin') {
        window.location.href = '/admin';
      } else {
        window.location.href = '/dashboard';
      }
    } catch (error) {
      console.error(error.message);
      setErrorMessage(error.message);
    }
  };

  return (
    
    <div className="login-container">
      
      <div className="form-container">
        <div className="login-title">
          <h2>Connexion</h2>
        </div>
        <div className='register-link'>
        <small><Link to='/register'><div><p>Devenir membre ici</p></div> </Link></small>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email" className="login-label">Email:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
              className="login-input"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password" className="login-label">Mot de passe:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              className="login-input"
              required
            />
          </div>
          
          <button type="submit">Se connecter</button>
        </form>
        <div className='forgot-password-link'><small><p>Mot de passe oublié</p></small></div>
      </div>
    </div>
  );
};

export default LoginForm;
 */

/*import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { login } from '../services/authService';
import './login.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('Sending login request with:', { email, password }); // Log the data being sent
      const responseData = await login(email, password); // Assurez-vous que les arguments sont passés correctement
      console.log('Login response data:', responseData); // Log the response data
      if (responseData.role === 'admin') {
        window.location.href = '/admin';
      } else {
        window.location.href = '/dashboard';
      }
    } catch (error) {
      console.error('Login error:', error.message); // Log the error
      setErrorMessage(error.message);
    }
  };

  return (
    <div className="login-container">
      <div className="form-container">
        <div className="login-title">
          <h2>Connexion</h2>
        </div>
        <div className='register-link'>
          <small><Link to='/register'><div><p>Devenir membre ici</p></div> </Link></small>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email" className="login-label">Email:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
              className="login-input"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password" className="login-label">Mot de passe:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              className="login-input"
              required
            />
          </div>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <button type="submit">Se connecter</button>
        </form>
        <Link to='/request-password-reset'><div className='forgot-password-link'><small><p>Mot de passe oublié</p></small></div></Link>
        
      </div>
    </div>
  );
};

export default Login;*/
//tentative de séparation du session storage depuis redis
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { login } from '../services/authService';
import './login.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const responseData = await login(email, password);
      if (responseData.role === 'admin') {
        window.location.href = '/admin';
      } else {
        window.location.href = '/dashboard';
      }
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <div className="login-container">
      <div className="form-container">
        <div className="login-title">
          <h2>Connexion</h2>
        </div>
        <div className='register-link'>
          <small><Link to='/register'><div><p>Devenir membre ici</p></div></Link></small>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email" className="login-label">Email:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
              className="login-input"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password" className="login-label">Mot de passe:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              className="login-input"
              required
            />
          </div>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <button type="submit">Se connecter</button>
        </form>
        <Link to='/request-password-reset'><div className='forgot-password-link'><small><p>Mot de passe oublié</p></small></div></Link>
      </div>
    </div>
  );
};

export default Login;

