/* import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Sidebar from './Sidebar'; import Stats from './Stats';
import UserManagement from './UserManagement';
import './styles/admindashboard.css';

const AdminDashboard = () => {
  return (
    <Router>
      <div className="dashboard">
        <Sidebar />
        <div className="dashboard-content">
          <Routes> <Route path="/admin/users" element={<UserManagement />} />
            <Route path="/admin/stats" element={<Stats />} />
            <Route path="/admin/settings" element={<h2>Settings</h2>} /> 
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default AdminDashboard;
 */

// src/components/AdminDashboard.js
import React from 'react';
import Sidebar from './Sidebar';
import './styles/admindashboard.css';

const AdminDashboard = ({ children }) => {
  return (
    <div className="dashboard">
      <Sidebar />
      <div className="dashboard-content">
        {children}
      </div>
    </div>
  );
};

export default AdminDashboard;
