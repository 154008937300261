import React, { useState } from 'react';
import { createObjectif } from '../services/objectifService';
import errorService from '../services/errorService';
import toastService from '../services/toastService';
import './objectif.css';

const Objectif = () => {
  const [duree, setDuree] = useState('');
  const [competence, setCompetence] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const objectif = `Dans ${duree}, je serai capable de ${competence}`;

    try {
      const data = await createObjectif(objectif);
      setMessage(data.message);
      toastService.notifySuccess(data.message);
      setDuree('');
      setCompetence('');
    } catch (error) {
      const errorMessage = errorService.handleAxiosError(error);
      setMessage(errorMessage);
      toastService.notifyError(errorMessage);
    }
  };

  return (
    <div className='objectif-container'>
      <h2>Déterminer votre objectif</h2>
      <p>
        Déterminer votre objectif
        Ici il s'agit de lorem ipsum dolor sit amet, consectetur adipiscing elit.lorem ipsum dolor sit amet, consectetur adipiscing elit.
        Et Epigonus quidem amictu tenus philosophus, ut apparuit, prece frustra temptata, sulcatis lateribus mortisque metu admoto turpi confessione cogitatorum socium, quae nulla erant, fuisse firmavit cum nec vidisset quicquam nec audisset penitus expers forensium rerum; Eusebius vero obiecta fidentius negans, suspensus in eodem gradu constantiae stetit latrocinium illud esse, non iudicium clamans.
      </p>
      <div className='objectif-form-container'>
        <form onSubmit={handleSubmit}>
          <label>
            Dans
            <input
              type="text"
              placeholder="12"
              value={duree}
              onChange={(e) => setDuree(e.target.value)}
              className='durée'
              required
            />
          </label>
          semaines, je serai capable de
          <input
            type="text"
            placeholder="..."
            value={competence}
            onChange={(e) => setCompetence(e.target.value)}
            className='competence'
            required
          />
          <button type="submit">Sauvegarder</button>
        </form>
      </div>
      {message && <p>{message}</p>}
    </div>
  );
};

export default Objectif;
