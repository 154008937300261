import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import './bientotPage.css';

const BientotPage = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) {
    setMessage('Veuillez entrer une adresse email valide.');
    return;
  }
      const response = await fetch('https://globoal.fr/api/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();
      if (response.ok) {
        setMessage(data.message);
      } else {
        setMessage(data.errors ? data.errors[0].msg : 'Une erreur est survenue');      
      }
      setEmail('');
    } catch (error) {
      setMessage('Une erreur est survenue');
    }
  };

  return (
    <div className="bientot-page">
      <ReactMarkdown>
        {`
---
## Globoal
---
# Qu'est-ce que c'est ?

---

 Pourquoi s'inscrire 
---
Globoal, pronnoncé "globol",

Est un site (et bientôt une application) qui a pour vocation d'informer, et d'aider à atteindre ses objectifs personnels. Tout ceci en vous fournissant d'abord, des ressources gratuites via des articles de blog. 

Ces articles, partagés au fur et à mesure abordent les thèmes de la santé, la psycholgie, et le bien-être en général.
Avec le temps, de nouveaux outils seront mis à disposition tels que des indicateurs de suivi. 

Il sera possible de suivre par exemple la qualité de votre sommeil, vos habitudes alimentaires, et plus encore. Ceux-ci vous permettrons de gagner en autonomie et d'apprendre à améliorer votre confort de vie, et par là atteindre plus facilement vos objectifs.
Pourquoi s'inscrire ? Très simplement, vous serez les premiers avertis des nouveautés, et aurez la chance de tester les outils en avant première. Vous pourrez aussi enregistrer vos articles favoris, en proposer de nouveaux, partager sur les réseaux sociaux, et... d'autres choses incroyables, mais, on garde le secret pour l'instant !

Promis, vous serez agréablement surpris...


---
`}
</ReactMarkdown>
<form onSubmit={handleSubmit}>
<label htmlFor="email">Inscrivez-vous ici avec votre adresse:</label>
<input
          type="email"
          id="email"
          placeholder="Entrer mon adresse e-mail"
          value={email}
          onChange={handleEmailChange}
          required
        />
<button type="submit">Valider</button>
</form>
{message && <p>{message}</p>}
</div>
);
};

export default BientotPage;