import axios from './axiosConfig';

export const createArticle = async (articleData) => {
    const response = await axios.post('/blog/article/create', articleData);
    return response.data;
  };

  export const updateArticle = async (id, articleData) => {
    const response = await axios.put(`/blog/article/update/${id}`, articleData);
    return response.data;
  };

  export const uploadImage = async (imageFile) => {
    const formData = new FormData();
    formData.append('image', imageFile);
  
    const response = await axios.post('/files/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  
    return response.data;
  };
  
  export const getArticles = async (page, limit) => {
  const response = await axios.get(`/blog/article/all?page=${page}&limit=${limit}`);
  return response.data;
};

export const getArticleById = async (id) => {
    const response = await axios.get(`/blog/article/byid/${id}`);
    return response.data;
  };

  export const getArticleBySlug = async (slug) => {
    const response = await axios.get(`/blog/article/byslug/${slug}`);
    return response.data;
  };

  const articleService = {
    getArticles,
    getArticleById,
    uploadImage,
    getArticleBySlug,
    uploadImage,
    createArticle,
    updateArticle
  };
  
  export default articleService;
  