import React, { useState } from 'react';
import axios from 'axios';
import { notifySuccess, notifyError } from '../../services/toastService';
import './requestPasswordReset.css';

const RequestPasswordReset = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post('https://localhost:5000/api/auth/request-password-reset', { email });
      notifySuccess(response.data.message);
    } catch (error) {
      notifyError(error.response?.data?.message || 'Error sending password reset email');
    }
  };

  return (
    <div className="request-password-reset-container">
      <div className="form-container">
        <div className="request-password-reset-title">
          <h2>Demande de Réinitialisation de Mot De Passe</h2>
        </div>
        <p>Saisissez l'email du compte concerné pour recevoir un lien de modification de mot de passe:</p>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email" className="request-password-reset-label">Email:</label>
            <input
              type="email"
              id="email"
              placeholder="Saisissez votre email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="request-password-reset-input"
              required
            />
          </div>
          <button type="submit">Recevoir Le Lien</button>
        </form>
      </div>
    </div>
  );
};

export default RequestPasswordReset;