/* import React, { useState, useEffect } from 'react';
import trackerService from '../services/trackerService';
import userTrackerEntriesService from '../services/userTrackerEntriesService';

const WaterConsumption = () => {
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const [consumptions, setConsumptions] = useState([]);
    
    const userId = sessionStorage.getItem('userId')?.replace(/"/g, '');

    const handleAddWater = async (amount) => {
        try {
            const date = new Date().toISOString();
            const response = await trackerService.addWater(userId, amount, date);
            console.log('Add water response:', response);

            if (response.success) {
                setMessage('Entry added successfully');
                setError(null);
                fetchWaterEntries(); 
            } else {
                setError('Error adding entry: ' + response.message);
                setMessage(null);
            }
        } catch (error) {
            console.error('Error adding entry:', error);
            setError('Error adding entry');
            setMessage(null);
        }
    };

    const fetchWaterEntries = async () => {
        try {
            console.log('Fetching water entries for user:', userId);
            const response = await userTrackerEntriesService.getWaterEntries(userId);
            console.log('API Response:', response);
            if (response.data && Array.isArray(response.data)) {
                setConsumptions(response.data || []);
                console.log('Water entries:', response.data);
            } else {
                setConsumptions([]);
                console.log('No water entries found in response.');
            }
            setError(null);
        } catch (error) {
            console.error('Error fetching entries:', error);
            setError('Error fetching entries');
            setConsumptions([]);
        }
    };

    useEffect(() => {
        fetchWaterEntries();
    }, []);

    return (
        <div className="water-consumption-container">
            <h1>Ma Consommation d'Eau</h1>
            {error && <div className="error">{error}</div>}
            {message && <div className="message">{message}</div>}
            <div className="buttons-container">
                {[10, 50, 100, 500].map(amount => (
                    <button
                        key={amount}
                        className="water-button"
                        onClick={() => handleAddWater(amount)}
                    >
                        +{amount} ml
                    </button>
                ))}
            </div>

            <div className="weight-entries-container">
                <h2>Eau Consommée :</h2>
                {consumptions.length === 0 ? (
                    <p>No water entries found.</p>
                ) : (
                    <ul>
                        {consumptions.map((entry) => (
                            <li key={entry._id}>
                                {entry.amount}ml, Le: {new Date(entry.date).toLocaleString()}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default WaterConsumption;
 */
import React, { useState, useEffect } from 'react';
import trackerService from '../services/trackerService';
import userTrackerEntriesService from '../services/userTrackerEntriesService';

const WaterConsumption = () => {
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const [consumptions, setConsumptions] = useState([]);

    const handleAddWater = async (amount) => {
        try {
            const date = new Date().toISOString();
            const response = await trackerService.addWater(amount, date);

            if (response.success) {
                setMessage('Entry added successfully');
                setError(null);
                fetchWaterEntries(); 
            } else {
                setError('Error adding entry: ' + response.message);
                setMessage(null);
            }
        } catch (error) {
            console.error('Error adding entry:', error);
            setError('Error adding entry');
            setMessage(null);
        }
    };

    const fetchWaterEntries = async () => {
        try {
            const response = await userTrackerEntriesService.getWaterEntries();
            if (response.success) {
                setConsumptions(response.data || []);
            } else {
                setConsumptions([]);
            }
            setError(null);
        } catch (error) {
            console.error('Error fetching entries:', error);
            setError('Error fetching entries');
            setConsumptions([]);
        }
    };

    useEffect(() => {
        fetchWaterEntries();
    }, []);

    return (
        <div className="water-consumption-container">
            <h1>Ma Consommation d'Eau</h1>
            {error && <div className="error">{error}</div>}
            {message && <div className="message">{message}</div>}
            <div className="buttons-container">
                {[10, 50, 100, 500].map(amount => (
                    <button
                        key={amount}
                        className="water-button"
                        onClick={() => handleAddWater(amount)}
                    >
                        +{amount} ml
                    </button>
                ))}
            </div>

            <div className="weight-entries-container">
                <h2>Eau Consommée :</h2>
                {consumptions.length === 0 ? (
                    <p>No water entries found.</p>
                ) : (
                    <ul>
                        {consumptions.map((entry) => (
                            <li key={entry._id}>
                                {entry.amount}ml, Le: {new Date(entry.date).toLocaleString()}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default WaterConsumption;
