/* import axiosInstance from './axiosConfig';
import { getUserProfile } from './authService';
import { toast } from 'react-toastify';

const trackerService = {
    // Mood
  getMoodTypes: async () => {
    try {
      const response = await axiosInstance.get('/moods/types');
      return response.data.moodTypes;
    } catch (error) {
      console.error("Error fetching mood types:", error);
      toast.error("Error fetching mood types");
      throw error;
    }
  },
  saveMood: async (userId, moodTypeId, date) => {
    try {
      const response = await axiosInstance.post('/moods', {
        userId,
        moodTypeId,
        date,
      });
      return response.data;
    } catch (error) {
      console.error("Error saving mood:", error);
      toast.error("Erreur d'enregistrement");
      throw error;
    }
  },

  // Sleep Quality

  addSleepQuality: async (userId, qualityScore, wokeUp, fellBackAsleep, rested) => {
    try {
        const response = await axiosInstance.post('/sleep-quality', {
            userId,
            qualityScore,
            wokeUp,
            fellBackAsleep,
            rested  
        }); return response.data;
    } catch (error) {
        console.error("Error saving sleep quality:", error);
        toast.error("Erreur d'enregistrement");
        throw error;
    }
  },

  //Sleep Quantity

  addSleepQuantity: async (userId, minutes, date) => {
    try {
        const response = await axiosInstance.post('/sleep-entries', {
            userId,
            minutes, 
            date  
        }); return response.data;
    } catch (error) {
        console.error("Error saving sleep quality:", error);
        toast.error("Erreur d'enregistrement");
        throw error;
    }
  },


   // Wake Sleep Record
   sleepRecord: async (userId, date) => {
    try {
      const sleepTime = new Date();
      const response = await axiosInstance.post('/sleep', {
        userId, 
        date,
        sleepTime
      });
      return { success: true, data: response.data };
    } catch (error) {
      console.error("Error saving sleep time", error);
      toast.error("Erreur d'enregistrement");
      throw error;
    }
  },

  wakeRecord: async (userId, date) => {
    try {
      const wakeTime = new Date();
      const response = await axiosInstance.post('/wake', {
        userId, 
        date,
        wakeTime
      });
      return { success: true, data: response.data };
    } catch (error) {
      console.error("Error saving wake time", error);
      toast.error("Erreur d'enregistrement");
      throw error;
    }
  },

  // Water
  addWater: async (userId, amount, date ) => {
    try {
        const response = await axiosInstance.post('/water-consumptions', 
        {userId, amount, date }
        );
        return {success: true, data: response.data};
    
    } catch (error) {
        return {success: false, message: error.message};
    }
  },

  // Weight tracker
  addWeightEntry: async (userId, weight, date) => {
    try {
      const response = await axiosInstance.post('/weight', { userId, weight, date });
      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, message: error.message };
    }
  },
}

export default trackerService;
 */

import axiosInstance from './axiosConfig';
import { toast } from 'react-toastify';
import { getUserProfile } from './authService';

const trackerService = {
  // Mood
  getMoodTypes: async () => {
    try {
      const response = await axiosInstance.get('/moods/types');
      return response.data.moodTypes;
    } catch (error) {
      console.error("Error fetching mood types:", error);
      toast.error("Error fetching mood types");
      throw error;
    }
  },
  
  saveMood: async (moodTypeId, date) => {
    try {
      const user = await getUserProfile();  
      const userId = user.userId;  
      const response = await axiosInstance.post('/moods', {
        userId,
        moodTypeId,
        date,
      });
      return response.data;
    } catch (error) {
      console.error("Error saving mood:", error);
      toast.error("Erreur d'enregistrement");
      throw error;
    }
  },

  // Sleep Quality
  addSleepQuality: async (qualityScore, wokeUp, fellBackAsleep, rested) => {
    try {
      const user = await getUserProfile();  
      const userId = user.userId;  
      const response = await axiosInstance.post('/sleep-quality', {
        userId,
        qualityScore,
        wokeUp,
        fellBackAsleep,
        rested  
      });
      return response.data;
    } catch (error) {
      console.error("Error saving sleep quality:", error);
      toast.error("Erreur d'enregistrement");
      throw error;
    }
  },

  //Sleep Quantity
  addSleepQuantity: async (minutes, date) => {
    try {
      const user = await getUserProfile();  
      const userId = user.userId;  
      const response = await axiosInstance.post('/sleep-entries', {
        userId,
        minutes, 
        date  
      });
      return response.data;
    } catch (error) {
      console.error("Error saving sleep quality:", error);
      toast.error("Erreur d'enregistrement");
      throw error;
    }
  },

  // Wake Sleep Record
  sleepRecord: async (date) => {
    try {
      const user = await getUserProfile();  
      const userId = user.userId;  
      const sleepTime = new Date();
      const response = await axiosInstance.post('/sleep', {
        userId, 
        date,
        sleepTime
      });
      return { success: true, data: response.data };
    } catch (error) {
      console.error("Error saving sleep time", error);
      toast.error("Erreur d'enregistrement");
      throw error;
    }
  },

  wakeRecord: async (date) => {
    try {
      const user = await getUserProfile();  
      const userId = user.userId;  
      const wakeTime = new Date();
      const response = await axiosInstance.post('/wake', {
        userId, 
        date,
        wakeTime
      });
      return { success: true, data: response.data };
    } catch (error) {
      console.error("Error saving wake time", error);
      toast.error("Erreur d'enregistrement");
      throw error;
    }
  },

  // Water
  addWater: async (amount, date) => {
    try {
      const user = await getUserProfile();  
      const userId = user.userId;  
      const response = await axiosInstance.post('/water-consumptions', 
        { userId, amount, date }
      );
      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, message: error.message };
    }
  },

  // Weight tracker
  addWeightEntry: async (weight, date) => {
    try {
      const user = await getUserProfile();  
      const userId = user.userId;  
      const response = await axiosInstance.post('/weight', { userId, weight, date });
      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, message: error.message };
    }
  },
};

export default trackerService;
