// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard {
    display: flex;
    height: 100vh;
  }
  
  .dashboard-content {
    flex: 1 1;
    padding: 20px;
    background-color: #f4f4f4;
    overflow-y: auto;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/admin/styles/admindashboard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;EACf;;EAEA;IACE,SAAO;IACP,aAAa;IACb,yBAAyB;IACzB,gBAAgB;EAClB","sourcesContent":[".dashboard {\n    display: flex;\n    height: 100vh;\n  }\n  \n  .dashboard-content {\n    flex: 1;\n    padding: 20px;\n    background-color: #f4f4f4;\n    overflow-y: auto;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
