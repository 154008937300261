// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
    width: 250px;
    background-color: #2c3e50;
    color: #ecf0f1;
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: fixed;
  }
  
  .sidebar-header {
    padding: 20px;
    background-color: #1abc9c;
    text-align: center;
  }
  
  .sidebar-menu {
    list-style: none;
    padding: 0;
  }
  
  .sidebar-menu li {
    padding: 15px 20px;
  }
  
  .sidebar-menu li a {
    color: #ecf0f1;
    text-decoration: none;
  }
  
  .sidebar-menu li a:hover {
    background-color: #34495e;
    display: block;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/admin/styles/sidebar.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,yBAAyB;IACzB,cAAc;IACd,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,yBAAyB;IACzB,kBAAkB;EACpB;;EAEA;IACE,gBAAgB;IAChB,UAAU;EACZ;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,cAAc;IACd,qBAAqB;EACvB;;EAEA;IACE,yBAAyB;IACzB,cAAc;EAChB","sourcesContent":[".sidebar {\n    width: 250px;\n    background-color: #2c3e50;\n    color: #ecf0f1;\n    display: flex;\n    flex-direction: column;\n    height: 100vh;\n    position: fixed;\n  }\n  \n  .sidebar-header {\n    padding: 20px;\n    background-color: #1abc9c;\n    text-align: center;\n  }\n  \n  .sidebar-menu {\n    list-style: none;\n    padding: 0;\n  }\n  \n  .sidebar-menu li {\n    padding: 15px 20px;\n  }\n  \n  .sidebar-menu li a {\n    color: #ecf0f1;\n    text-decoration: none;\n  }\n  \n  .sidebar-menu li a:hover {\n    background-color: #34495e;\n    display: block;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
