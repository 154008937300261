import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';

const Calendar = () => {
  return (
    <div>
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridWeek"
        events={[
          { title: 'Événement 1', date: '2024-05-15' },
          { title: 'Événement 2', date: '2024-05-20' }
        ]}
      />
    </div>
  );
};

export default Calendar;
