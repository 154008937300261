// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pinterest-share-button {
    display: inline-block;
    margin-top: 20px;
  }
  
  .pinterest-share-button img {
    width: 40px; 
    height: 40px;
    cursor: pointer;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/buttons/pinterestShareButton.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,eAAe;EACjB","sourcesContent":[".pinterest-share-button {\n    display: inline-block;\n    margin-top: 20px;\n  }\n  \n  .pinterest-share-button img {\n    width: 40px; \n    height: 40px;\n    cursor: pointer;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
