// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .contact-container h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .contact-container form div {
    margin-bottom: 15px;
  }
  
  .contact-container label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .contact-container input,
  .contact-container textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .contact-container button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #e7e04b;
    color: #5D22EE;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .contact-container button:hover {
    background-color: #aba537;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/contact/contact.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,yBAAyB;IACzB,uCAAuC;EACzC;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,kBAAkB;IAClB,iBAAiB;EACnB;;EAEA;;IAEE,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,cAAc;IACd,WAAW;IACX,aAAa;IACb,yBAAyB;IACzB,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".contact-container {\n    max-width: 600px;\n    margin: 0 auto;\n    padding: 20px;\n    background-color: #f9f9f9;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  }\n  \n  .contact-container h1 {\n    text-align: center;\n    margin-bottom: 20px;\n  }\n  \n  .contact-container form div {\n    margin-bottom: 15px;\n  }\n  \n  .contact-container label {\n    display: block;\n    margin-bottom: 5px;\n    font-weight: bold;\n  }\n  \n  .contact-container input,\n  .contact-container textarea {\n    width: 100%;\n    padding: 10px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n  }\n  \n  .contact-container button {\n    display: block;\n    width: 100%;\n    padding: 10px;\n    background-color: #e7e04b;\n    color: #5D22EE;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n  }\n  \n  .contact-container button:hover {\n    background-color: #aba537;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
