// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mood-types-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.category {
  flex: 1 1;
  margin: 0 10px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
}

.category-title {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.subsets-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.subset {
  flex: 1 1;
  margin: 0 10px;
}

.subset-title {
  font-size: 1.2em;
  margin-bottom: 5px;
}

.mood-type-list {
  list-style: none;
  padding: 0;
}

.mood-type-item {
  margin-bottom: 5px;
  cursor: pointer;
}
.mood-type-item:hover {
  color: rgb(86, 68, 188); 
}


`, "",{"version":3,"sources":["webpack://./src/components/moodIndicator.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,aAAa;AACf;;AAEA;EACE,SAAO;EACP,cAAc;EACd,sBAAsB;EACtB,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,eAAe;AACjB;;AAEA;EACE,SAAO;EACP,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,uBAAuB;AACzB","sourcesContent":[".mood-types-container {\n  display: flex;\n  justify-content: space-between;\n  padding: 20px;\n}\n\n.category {\n  flex: 1;\n  margin: 0 10px;\n  border: 1px solid #ccc;\n  padding: 10px;\n  border-radius: 5px;\n}\n\n.category-title {\n  font-size: 1.5em;\n  margin-bottom: 10px;\n}\n\n.subsets-container {\n  display: flex;\n  justify-content: space-between;\n  flex-wrap: wrap;\n}\n\n.subset {\n  flex: 1;\n  margin: 0 10px;\n}\n\n.subset-title {\n  font-size: 1.2em;\n  margin-bottom: 5px;\n}\n\n.mood-type-list {\n  list-style: none;\n  padding: 0;\n}\n\n.mood-type-item {\n  margin-bottom: 5px;\n  cursor: pointer;\n}\n.mood-type-item:hover {\n  color: rgb(86, 68, 188); \n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
