/* import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const UserDetails = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [updatedEmail, setUpdatedEmail] = useState('');
  const [updatedRole, setUpdatedRole] = useState('');
  const token = sessionStorage.getItem('token');

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch(`http://localhost:5000/api/user/byid/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des détails de l\'utilisateur');
        }
        const data = await response.json();
        setUser(data);
        setUpdatedEmail(data.email);
        setUpdatedRole(data.role);
      } catch (error) {
        console.error(error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [id, token]);

  const handleUpdateUser = async (e) => {
    e.preventDefault();
    const updatedUserData = { email: updatedEmail, role: updatedRole };

    try {
      const response = await fetch(`http://localhost:5000/api/user/update/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(updatedUserData)
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la mise à jour de l\'utilisateur');
      }

      const updatedUser = await response.json();
      setUser(updatedUser);
      setIsEditing(false);
    } catch (error) {
      console.error(error);
      setError(error.message);
    }
  };

  if (loading) {
    return <p>Loading user details...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className='container'>
      {user ? (
        <div>
          {isEditing ? (
            <form onSubmit={handleUpdateUser}>
              <div>
                <p>Edition  en cours ⛑</p>
                <label>Email:</label>
                <input
                  type="email"
                  value={updatedEmail}
                  onChange={(e) => setUpdatedEmail(e.target.value)}
                />
              </div>
              <div>
                <label>Role:</label>
                <input
                  type="text"
                  value={updatedRole}
                  onChange={(e) => setUpdatedRole(e.target.value)}
                />
              </div>
              <button type="submit">Save Changes</button>
              <button type="button" onClick={() => setIsEditing(false)}>Cancel</button>
            </form>
          ) : (
            <div>
              <h2>{user.email} - {user._id}</h2>
              <p>Nom d'utilisateur: {user.username}</p>
              <p>Inscrit le: {user.createdAt}</p>
              <p>Role: {user.role}</p>
              <button onClick={() => setIsEditing(true)}>Edit</button>
            </div>
          )}
        </div>
      ) : (
        <p>No user found</p>
      )}
    </div>
  );
};

export default UserDetails;
 */

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import userService from '../services/userService';

const UserDetails = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [updatedEmail, setUpdatedEmail] = useState('');
  const [updatedRole, setUpdatedRole] = useState('');

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const data = await userService.getUserById(id);
        setUser(data);
        setUpdatedEmail(data.email);
        setUpdatedRole(data.role);
      } catch (error) {
        console.error(error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [id]);

  const handleUpdateUser = async (e) => {
    e.preventDefault();
    const updatedUserData = { email: updatedEmail, role: updatedRole };

    try {
      const updatedUser = await userService.updateUser(id, updatedUserData);
      setUser(updatedUser);
      setIsEditing(false);
    } catch (error) {
      console.error(error);
      setError(error.message);
    }
  };

  if (loading) {
    return <p>Loading user details...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className='container'>
      {user ? (
        <div>
          {isEditing ? (
            <form onSubmit={handleUpdateUser}>
              <div>
                <p>Edition  en cours ⛑</p>
                <label>Email:</label>
                <input
                  type="email"
                  value={updatedEmail}
                  onChange={(e) => setUpdatedEmail(e.target.value)}
                />
              </div>
              <div>
                <label>Role:</label>
                <input
                  type="text"
                  value={updatedRole}
                  onChange={(e) => setUpdatedRole(e.target.value)}
                />
              </div>
              <button type="submit">Save</button>
              <button type="button" onClick={() => setIsEditing(false)}>Cancel</button>
            </form>
          ) : (
            <div>
              <h2>{user.email} - {user._id}</h2>
              <p>Nom d'utilisateur: {user.username}</p>
              <p>Inscrit le: {user.createdAt}</p>
              <p>Role: {user.role}</p>
              <button onClick={() => setIsEditing(true)}>Edit</button>
            </div>
          )}
        </div>
      ) : (
        <p>No user found</p>
      )}
    </div>
  );
};

export default UserDetails;
