// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner {
  background-color: #e7e04b; /* Couleur de fond */
  color: #721c24; /* Couleur du texte */
  padding: 20px; /* Espacement intérieur */
  text-align: center; /* Centrage du texte */
  font-size: 18px; /* Taille de la police */
  font-weight: bold; /* Gras */
  border-bottom: 2px solid #721c24; /* Bordure en bas */
  transition: background-color 0.3s ease, border-bottom-color 0.3s ease;
  /* transition: propriété à animer, durée, type de transition */
}

.banner-link {
  text-decoration: none; /* Supprime le soulignement du lien */
  color: inherit; /* Hérite de la couleur du texte */
  display: block; /* Le lien occupe tout l'espace de la bannière */
}

.banner a {
  color: #721c24; /* Couleur du lien */
  text-decoration: underline; /* Soulignement */
  transition: color 0.3s ease, -webkit-text-decoration 0.3s ease;
  transition: color 0.3s ease, text-decoration 0.3s ease;
  transition: color 0.3s ease, text-decoration 0.3s ease, -webkit-text-decoration 0.3s ease;
}

.banner a:hover {
  color: #721c24; /* Couleur du lien au survol */
  text-decoration: none; /* Aucun soulignement au survol */
}

.banner:hover {
  background-color: #d1cb41; /* Couleur de fond au survol */
  border-bottom-color: #5f1a1c; /* Couleur de la bordure au survol */
}
`, "",{"version":3,"sources":["webpack://./src/components/bientotBanner.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB,EAAE,oBAAoB;EAC/C,cAAc,EAAE,qBAAqB;EACrC,aAAa,EAAE,yBAAyB;EACxC,kBAAkB,EAAE,sBAAsB;EAC1C,eAAe,EAAE,wBAAwB;EACzC,iBAAiB,EAAE,SAAS;EAC5B,gCAAgC,EAAE,mBAAmB;EACrD,qEAAqE;EACrE,8DAA8D;AAChE;;AAEA;EACE,qBAAqB,EAAE,qCAAqC;EAC5D,cAAc,EAAE,kCAAkC;EAClD,cAAc,EAAE,gDAAgD;AAClE;;AAEA;EACE,cAAc,EAAE,oBAAoB;EACpC,0BAA0B,EAAE,iBAAiB;EAC7C,8DAAsD;EAAtD,sDAAsD;EAAtD,yFAAsD;AACxD;;AAEA;EACE,cAAc,EAAE,8BAA8B;EAC9C,qBAAqB,EAAE,iCAAiC;AAC1D;;AAEA;EACE,yBAAyB,EAAE,8BAA8B;EACzD,4BAA4B,EAAE,oCAAoC;AACpE","sourcesContent":[".banner {\n  background-color: #e7e04b; /* Couleur de fond */\n  color: #721c24; /* Couleur du texte */\n  padding: 20px; /* Espacement intérieur */\n  text-align: center; /* Centrage du texte */\n  font-size: 18px; /* Taille de la police */\n  font-weight: bold; /* Gras */\n  border-bottom: 2px solid #721c24; /* Bordure en bas */\n  transition: background-color 0.3s ease, border-bottom-color 0.3s ease;\n  /* transition: propriété à animer, durée, type de transition */\n}\n\n.banner-link {\n  text-decoration: none; /* Supprime le soulignement du lien */\n  color: inherit; /* Hérite de la couleur du texte */\n  display: block; /* Le lien occupe tout l'espace de la bannière */\n}\n\n.banner a {\n  color: #721c24; /* Couleur du lien */\n  text-decoration: underline; /* Soulignement */\n  transition: color 0.3s ease, text-decoration 0.3s ease;\n}\n\n.banner a:hover {\n  color: #721c24; /* Couleur du lien au survol */\n  text-decoration: none; /* Aucun soulignement au survol */\n}\n\n.banner:hover {\n  background-color: #d1cb41; /* Couleur de fond au survol */\n  border-bottom-color: #5f1a1c; /* Couleur de la bordure au survol */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
