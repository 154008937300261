import axios from 'axios';
import { notifyError, notifySuccess } from './toastService';

const axiosInstance = axios.create({
  /* baseURL: 'http://localhost:5000/api', */
  baseURL: 'https://globoal.fr/api', 
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  timeout: 10000, 
});

axiosInstance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.data && response.data.message) {
      notifySuccess(response.data.message);
    }
    return response;
  },
  (error) => {
    if (error.response && error.response.data && error.response.data.message) {
      notifyError(error.response.data.message);
    } else if (error.request) {
      notifyError('Erreur de connexion. Veuillez vérifier votre réseau.');
    } else {
      notifyError('Une erreur est survenue. Veuillez réessayer.');
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
