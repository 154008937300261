import React from 'react';
import { Link } from 'react-router-dom';
import './styles/sidebar.css';

const Sidebar = () => {
  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <h2>Admin Dashboard</h2>
      </div>
      <ul className="sidebar-menu">
        <li><Link to="/admin/users">Users</Link></li>
        <li><Link to="/admin/articles">Articles</Link></li>
        <li>{/* <Link to="/admin/settings">Settings</Link> */}Articles</li>
      </ul>
    </div>
  );
};

export default Sidebar;
