// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Style général pour le composant */
.indicators-container {
    font-family: Arial, sans-serif;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Style pour les titres */
  .indicators-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  /* Style pour le menu select */
  .indicator-select {
    width: 100%;
    margin-bottom: 20px;
    padding: 10px;
    font-size: 1rem;
  }
  
  /* Style pour les options du menu select */
  .indicator-option {
    font-size: 1rem;
  }
  
  /* Style pour les indicateurs sélectionnés */
  .selected-indicator {
    margin-bottom: 10px;
  }
  
  /* Style pour les boutons */
  .save-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .save-button:hover {
    background-color: #0056b3;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/MesIndicateurs.css"],"names":[],"mappings":"AAAA,oCAAoC;AACpC;IACI,8BAA8B;IAC9B,gBAAgB;IAChB,cAAc;IACd,aAAa;EACf;;EAEA,0BAA0B;EAC1B;IACE,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA,8BAA8B;EAC9B;IACE,WAAW;IACX,mBAAmB;IACnB,aAAa;IACb,eAAe;EACjB;;EAEA,0CAA0C;EAC1C;IACE,eAAe;EACjB;;EAEA,4CAA4C;EAC5C;IACE,mBAAmB;EACrB;;EAEA,2BAA2B;EAC3B;IACE,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["/* Style général pour le composant */\n.indicators-container {\n    font-family: Arial, sans-serif;\n    max-width: 600px;\n    margin: 0 auto;\n    padding: 20px;\n  }\n  \n  /* Style pour les titres */\n  .indicators-title {\n    font-size: 1.5rem;\n    margin-bottom: 10px;\n  }\n  \n  /* Style pour le menu select */\n  .indicator-select {\n    width: 100%;\n    margin-bottom: 20px;\n    padding: 10px;\n    font-size: 1rem;\n  }\n  \n  /* Style pour les options du menu select */\n  .indicator-option {\n    font-size: 1rem;\n  }\n  \n  /* Style pour les indicateurs sélectionnés */\n  .selected-indicator {\n    margin-bottom: 10px;\n  }\n  \n  /* Style pour les boutons */\n  .save-button {\n    background-color: #007bff;\n    color: white;\n    border: none;\n    padding: 10px 20px;\n    font-size: 1rem;\n    cursor: pointer;\n  }\n  \n  .save-button:hover {\n    background-color: #0056b3;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
