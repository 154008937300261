/* import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import Blog from './components/Blog';
import AdminDashboard from './components/admin/AdminDashboard';
import Calendar from './components/Calendar';
import Dashboard from './components/Dashboard';
import ArticleList from './components/ArticleList';
import ArticleDetails from './components/ArticleDetails';
import UsersList from './components/UsersList';
import UsersDetails from './components/UsersDetails';
import Objectif from './components/Objectif';
import ArticleForm from './components/ArticleForm';
import ArticleEdition from './components/ArticleEdition';
import Bientot from './components/Bientot';
import Homepage from './HomePage';
import BackToTopButton from './components/BackToTopButton';
import Layout from './components/Layout';
import MesIndicateurs from './components/MesIndicateurs';
import MoodIndicator from './components/MoodIndicator';
import Register from './components/Register';
import VerifyEmail from './components/verifyEmail/VerifyEmail';
import Contact from './components/contact/Contact';
import { isAuthenticated, getUserProfile, logout } from './services/authService';
import UserManagement from './components/admin/UserManagement';
import ArticleManagement from './components/admin/ArticleManagement';
import RequestPasswordReset from './components/password/RequestPasswordReset';
import ResetPassword from './components/password/ResetPassword';
import { ToastContainer } from 'react-toastify';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState('');
  useEffect(() => {
   const checkAuth = async () => {
  const authenticated = await isAuthenticated(); 

  if (authenticated) {
    setIsLoggedIn(true);
    try {
      const user = await getUserProfile();
      setUserRole(user.role);
    } catch (error) {
      console.error('Erreur lors de la récupération du profil utilisateur:', error.message);
      setIsLoggedIn(false);
    }
  } else {
    console.log('Utilisateur non authentifié');
    setIsLoggedIn(false);
  }
};
checkAuth();
}, []);

  const handleLogout = async () => {
    try {
      await logout();
      setIsLoggedIn(false);
      setUserRole('');
      window.location.href = "/";
    } catch (error) {
      console.error('Erreur lors de la déconnexion:', error.message);
    }
  };

  return (
    <BrowserRouter>
      <Layout isLoggedIn={isLoggedIn} handleLogout={handleLogout} userRole={userRole}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/admin" element={<AdminDashboard />} />
          <Route path="/articlelist" element={<ArticleList />} />
          <Route path="/article/details/:slug" element={<ArticleDetails />} />
          <Route path="/userslist" element={<UsersList />} />
          <Route path="/usersdetails/:id" element={<UsersDetails />} />
          <Route path="/objectif" element={<Objectif />} />
          <Route path="/create/article" element={<ArticleForm />} />
          <Route path="/article/edit/:id" element={<ArticleEdition />} />
          <Route path="/a-propos" element={<Bientot />} />
          <Route path="/home" element={<Homepage />} />
          <Route path="/" element={<Homepage />} />
          <Route path="/mesindicateurs" element={<MesIndicateurs />} />
          <Route path="/mood" element={<MoodIndicator />} />
          <Route path='/register' element={<Register />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/request-password-reset" element={<RequestPasswordReset />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/admin/users" element={<UserManagement />} />
          <Route path="/admin/articles" element={<ArticleManagement />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <BackToTopButton />
      </Layout>
      <ToastContainer />
    </BrowserRouter>
  );
};

export default App;


 */

import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import Blog from './components/Blog';
import AdminDashboard from './components/admin/AdminDashboard';
import Calendar from './components/Calendar';
import Dashboard from './components/Dashboard';
import ArticleList from './components/ArticleList';
import ArticleDetails from './components/ArticleDetails';
import UsersList from './components/UsersList';
import UsersDetails from './components/UsersDetails';
import Objectif from './components/Objectif';
import ArticleForm from './components/ArticleForm';
import ArticleEdition from './components/ArticleEdition';
import Bientot from './components/Bientot';
import Homepage from './HomePage';
import BackToTopButton from './components/BackToTopButton';
import Layout from './components/Layout';
import MesIndicateurs from './components/MesIndicateurs';
import MoodIndicator from './components/MoodIndicator';
import Register from './components/Register';
import VerifyEmail from './components/verifyEmail/VerifyEmail';
import Contact from './components/contact/Contact';
import { isAuthenticated, getUserProfile, logout } from './services/authService';
import UserManagement from './components/admin/UserManagement';
import ArticleManagement from './components/admin/ArticleManagement';
import RequestPasswordReset from './components/password/RequestPasswordReset';
import ResetPassword from './components/password/ResetPassword';
import { ToastContainer } from 'react-toastify';
import ProtectedRoute from './components/ProtectedRoute';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      setIsLoading(true);
      try {
        const authenticated = await isAuthenticated();
        if (authenticated) {
          setIsLoggedIn(true);
          const user = await getUserProfile();
          setUserRole(user.role);
        } else {
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération du profil utilisateur:', error.message);
        setIsLoggedIn(false);
      } finally {
        setIsLoading(false); 
      }
    };

    checkAuth();
  }, []);

  const handleLogout = async () => {
    try {
      await logout();
      setIsLoggedIn(false);
      setUserRole('');
      window.location.href = "/";
    } catch (error) {
      console.error('Erreur lors de la déconnexion:', error.message);
    }
  };

  if (isLoading) {
    return <div>Chargement...</div>;
  }

  return (
    <BrowserRouter>
      <Layout isLoggedIn={isLoggedIn} handleLogout={handleLogout} userRole={userRole}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/dashboard" element={<ProtectedRoute isLoggedIn={isLoggedIn} element={Dashboard} />} />
          <Route path="/calendar" element={<ProtectedRoute isLoggedIn={isLoggedIn} element={Calendar} />} />
          <Route path="/admin" element={<ProtectedRoute isLoggedIn={isLoggedIn} element={AdminDashboard} />} />
          <Route path="/articlelist" element={<ArticleList />} />
          <Route path="/article/details/:slug" element={<ArticleDetails />} />
          <Route path="/userslist" element={<ProtectedRoute isLoggedIn={isLoggedIn} element={UsersList} />} />
          <Route path="/usersdetails/:id" element={<ProtectedRoute isLoggedIn={isLoggedIn} element={UsersDetails} />} />
          <Route path="/objectif" element={<ProtectedRoute isLoggedIn={isLoggedIn} element={Objectif} />} />
          <Route path="/create/article" element={<ProtectedRoute isLoggedIn={isLoggedIn} element={ArticleForm} />} />
          <Route path="/article/edit/:id" element={<ProtectedRoute isLoggedIn={isLoggedIn} element={ArticleEdition} />} />
          <Route path="/a-propos" element={<Bientot />} />
          <Route path="/home" element={<Homepage />} />
          <Route path="/" element={<Homepage />} />
          <Route path="/mesindicateurs" element={<ProtectedRoute isLoggedIn={isLoggedIn} element={MesIndicateurs} />} />
          <Route path="/mood" element={<ProtectedRoute isLoggedIn={isLoggedIn} element={MoodIndicator} />} />
          <Route path='/register' element={<ProtectedRoute isLoggedIn={isLoggedIn} element={Register} />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/request-password-reset" element={<RequestPasswordReset />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/admin/users" element={<ProtectedRoute isLoggedIn={isLoggedIn} element={UserManagement} />} />
          <Route path="/admin/articles" element={<ProtectedRoute isLoggedIn={isLoggedIn} element={ArticleManagement} />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <BackToTopButton />
      </Layout>
      <ToastContainer />
    </BrowserRouter>
  );
};

export default App;