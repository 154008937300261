import axios from './axiosConfig';
import { getUserProfile } from './authService';

export const getAllUsers = async (page, limit) => {
  try {
  const response = await axios.get(`user/all?page=${page}&limit=${limit}`);
  return response.data;
} catch (error) {
  console.error('Erreur lors de la récupération de la liste des utilisateurs', error);
  throw error;
}
};

export const getUserById = async (id) => {
  try {
  const response = await axios.get(`/user/byid/${id}`);
  return response.data;
  } catch (error) {
    console.error('Erreur lors de la récupération de l\'utilisateur par ID', error);
    throw error;
  }
};

export const updateUser = async (id, userData) => {
  try {
  const response = await axios.put(`/user/update/${id}`, userData);
  return response.data;
} catch (error) {
  console.error('Erreur lors de la modification de l\'utilisateur', error);
  throw error;
}
};

export const deleteUser = async (id) => {
  try {
  const response = await axios.delete(`/user/delete/${id}`);
  return response.data;
} catch (error) {
  console.error('Erreur lors de la supression de l\'utilisateur', error);
  throw error;
}
};

const userService = {
  getAllUsers,
  getUserById,
  updateUser,
  deleteUser,
};

export default userService;
