import React from 'react';
import { Link } from "react-router-dom";
import './bientotBanner.css'; 

const BientotBanner = () => {
  return (
    <Link to="/a-propos" className="banner-link">

    <div className="banner">
      <p>BIENTÔT DISPONIBLE : Un suivi de mes habitudes de vie, et plus encore ! Inscrivez-vous et profitez en avant-première des nouveautés</p>
    </div>
    </Link>
  );
};

export default BientotBanner;
