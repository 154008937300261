/* import React, { useState, useEffect } from "react";
import trackerService from "../services/trackerService";
import userTrackerEntriesService from "../services/userTrackerEntriesService";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./moodIndicator.css";

const MoodIndicator = () => {
  const [moodTypes, setMoodTypes] = useState([]);
  const [organizedMoodTypes, setOrganizedMoodTypes] = useState({});

  useEffect(() => {
    const fetchMoodTypes = async () => {
      try {
        const moodTypes = await trackerService.getMoodTypes();
        setMoodTypes(moodTypes);
      } catch (error) {
        console.error("Error fetching mood types:", error);
      }
    };

    fetchMoodTypes();
  }, []);

  useEffect(() => {
    const organizeMoodTypes = () => {
      const organized = {};

      moodTypes.forEach((moodType) => {
        const { category, subset } = moodType;

        if (!organized[category]) {
          organized[category] = {};
        }

        if (!organized[category][subset]) {
          organized[category][subset] = [];
        }

        organized[category][subset].push(moodType);
      });

      setOrganizedMoodTypes(organized);
    };

    organizeMoodTypes();
  }, [moodTypes]);

  const handleMoodTypeClick = async (moodTypeId) => {
    try {
      const userId = sessionStorage.getItem('userId').replace(/"/g, '');
      const date = new Date().toISOString();

      const response = await trackerService.saveMood(userId, moodTypeId, date);

      if (response.success) {
        toast.success("Mood saved successfully");
        console.log("Mood saved:", response.mood);
      } else {
        toast.error("Failed to save mood");
        console.error("Failed to save mood:", response.message);
      }
    } catch (error) {
      console.error("Error saving mood:", error);
    }
  };

  return (
    <div className="mood-types-container">
      <ToastContainer />
      {Object.keys(organizedMoodTypes).map((category) => (
        <div key={category} className="category">
          <h2 className="category-title">{category}</h2>
          <div className="subsets-container">
            {Object.keys(organizedMoodTypes[category]).map((subset) => (
              <div key={subset} className="subset">
                <h3 className="subset-title">{subset}</h3>
                <ul className="mood-type-list">
                  {organizedMoodTypes[category][subset].map((moodType) => (
                    <li
                      key={moodType._id}
                      className="mood-type-item"
                      onClick={() => handleMoodTypeClick(moodType._id)}
                    >
                      {moodType.type}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MoodIndicator;
 */

import React, { useState, useEffect } from "react";
import trackerService from "../services/trackerService";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./moodIndicator.css";

const MoodIndicator = () => {
  const [moodTypes, setMoodTypes] = useState([]);
  const [organizedMoodTypes, setOrganizedMoodTypes] = useState({});

  useEffect(() => {
    const fetchMoodTypes = async () => {
      try {
        const moodTypes = await trackerService.getMoodTypes();
        setMoodTypes(moodTypes);
      } catch (error) {
        console.error("Error fetching mood types:", error);
      }
    };

    fetchMoodTypes();
  }, []);

  useEffect(() => {
    const organizeMoodTypes = () => {
      const organized = {};

      moodTypes.forEach((moodType) => {
        const { category, subset } = moodType;

        if (!organized[category]) {
          organized[category] = {};
        }

        if (!organized[category][subset]) {
          organized[category][subset] = [];
        }

        organized[category][subset].push(moodType);
      });

      setOrganizedMoodTypes(organized);
    };

    organizeMoodTypes();
  }, [moodTypes]);

  const handleMoodTypeClick = async (moodTypeId) => {
    try {
      const date = new Date().toISOString();
      const response = await trackerService.saveMood(moodTypeId, date);

      if (response.success) {
        toast.success("Mood saved successfully");
        console.log("Mood saved:", response.mood);
      } else {
        toast.error("Failed to save mood");
        console.error("Failed to save mood:", response.message);
      }
    } catch (error) {
      console.error("Error saving mood:", error);
    }
  };

  return (
    <div className="mood-types-container">
      <ToastContainer />
      {Object.keys(organizedMoodTypes).map((category) => (
        <div key={category} className="category">
          <h2 className="category-title">{category}</h2>
          <div className="subsets-container">
            {Object.keys(organizedMoodTypes[category]).map((subset) => (
              <div key={subset} className="subset">
                <h3 className="subset-title">{subset}</h3>
                <ul className="mood-type-list">
                  {organizedMoodTypes[category][subset].map((moodType) => (
                    <li
                      key={moodType._id}
                      className="mood-type-item"
                      onClick={() => handleMoodTypeClick(moodType._id)}
                    >
                      {moodType.type}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MoodIndicator;
